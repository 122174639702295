<template>
    <Modal :title="$t('iWantleaveMessage')" ref="modal" :width="640" :confirmLoading="loading" :height="500" cancel
        :confirm="$t('confirmMessage')" @confirm="save">
        <TextArea :rows="12" :placeholder="$t('inputMessage')" v-model="message"></TextArea>
    </Modal>
</template>

<script>
import { Modal as AModal } from 'ant-design-vue';
import api from '@/api/shop';
import { message } from 'ant-design-vue';

export default {
    name: "ConcatUs",
    components: { AModal },
    data() {
        return {
            /**
             * loading
             */
            loading: false,
            /**
             * 内容
             */
            message: ''
        }
    },
    props: {
        shopId: [Number, String]
    },
    methods: {
        /**
         * 保存
         */
        async save() {
            try {
                if (!this.message.trim()) return message.warn(this.$t('msgEmpty'));
                this.loading = true;
                await api.concatShop({
                    shopId: this.shopId,
                    message: this.message
                });
                this.hide();
            } catch (error) { }
            this.loading = false;
        },
        /**
         * 显示
         */
        show() {
            this.message = '';
            this.$refs.modal.show();
        },
        /**
         * 隐藏
         */
        hide() {
            this.$refs.modal.hide();
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-content {

    .ant-modal-body {
        padding: 30px;
    }

    .ant-modal-footer {

        &>.button {
            width: 280px;
            height: 54px;
            border-radius: 27px;
        }
    }
}
</style>