<template>
    <div>
        <section class="top">
            <!-- 头部 -->
            <Header />
            <!-- 搜索区 -->
            <Search />
        </section>
        <div class="empty"></div>
        <keep-alive :include="['scoreRule', 'privateTerm', 'joinAgreement']">
            <router-view></router-view>
        </keep-alive>
        <!-- 底部 -->
        <Footer />
    </div>
</template>

<script>
import Header from './components/Header.vue';
import Search from './components/Search.vue';
import Footer from './components/Footer.vue';
import { mapActions } from 'vuex';

export default {
    name: 'MainLayout',
    components: {
        Header,
        Search,
        Footer
    },
    methods: {
        ...mapActions(['getInfoCounts', 'getHome'])
    },
    created() {
        this.getInfoCounts();
        this.getHome();
    }
}
</script>

<style lang="scss" scoped>
.top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.empty {
    height: 167px;
}
</style>