<template>
    <ul :ar="$i18n.locale === 'ar'">
        <li v-for="item in list" :key="item.categoryId">
            <input @click="$emit('input', item.categoryId) && $emit('change', item.categoryId)"
                type="checkbox" :style="{ height: 56 - 4 * level + 'px' }">
            <div :active="value === item.categoryId" class="item"
                :style="{ [$i18n.locale === 'ar' ? 'paddingRight' : 'paddingLeft']: 10 + 20 * level + 'px' }">
                <span class="title" :style="{ fontSize: 18 - level * 2 + 'px' }">{{ item.categoryName }}</span>
                <em v-if="item.childList?.length"></em>
            </div>
            <Tree v-on="$listeners" :value="value" v-if="item.childList?.length" :list="item.childList"
                :level="level + 1" />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Tree',
    props: {
        /**
         * 值
         */
        value: [String, Number],
        /**
         * 数据
         */
        list: Array,
        /**
         * 级别
         */
        level: {
            type: Number,
            default: 0
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
ul {
    &[ar] {
        direction: rtl;

        li {
            .item {
                .title {
                    padding: 0 0 0 9px;
                }
            }
        }
    }

    li {
        position: relative;

        &>input {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            display: block;
            width: 100%;
            z-index: 9;

            &:checked {
                &+div em {
                    background: url(@/assets/imgs/home/up.png);
                }

                &+div+ul {
                    display: none;
                }
            }
        }

        .item {
            padding: 10px 0;

            &[active] {
                background: #E2EDF8;
            }

            em {
                display: inline-block;
                width: 10px;
                height: 6px;
                background: url(@/assets/imgs/home/down.png);
            }

            .title {
                font-family: middle;
                font-weight: 500;
                color: #555555;
                padding-right: 9px;
            }
        }
    }
}
</style>