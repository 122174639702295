<template>
    <footer :ar="$i18n.locale === 'ar'">
        <section class="top">
            <img class="logo" src="@/assets/logo_footer.png" alt="">
            <div class="concat">
                <div v-t="'concat'"></div>
                <ul>
                    <li>
                        <img src="@/assets/imgs/home/定位(1).png" alt="">
                        <span>{{ info[0]?.vale }}</span>
                    </li>
                    <li>
                        <img src="@/assets/imgs/home/电话 拷贝.png" alt="">
                        <span>{{ info[1]?.vale }}</span>
                    </li>
                    <li @click="$refs.modal.show()">
                        <img src="@/assets/imgs/home/邮箱(1) 拷贝.png" alt="">
                        <span>{{ info[2]?.vale }}</span>
                    </li>
                </ul>
            </div>
            <div class="right">
                <dl v-for="item in footerMap" :key="item.title">
                    <dt v-t="item.title"></dt>
                    <dd @click="goto(child)" v-for="child in item.children" :key="child" v-t="child"></dd>
                </dl>
            </div>
        </section>
        <section class="bottom">
            <div>
                <span v-t="'private'" @click="$router.push({ name: 'privateTerm' })"></span>
                <span v-t="'orderTerm'" @click="$router.push({ name: 'orderTerm' })"></span>
            </div>
            <div class="copyright" v-t="'copyright'"></div>
            <div v-t="'beian'"></div>
        </section>
        
        <Sendemail ref="modal" />

    </footer>
</template>

<script>
const footerMap = [{ title: 'about', children: ['open', 'scoreRule', 'qa', 'map'] },
{ title: 'paymentOptions', children: [ 'receivePay', 'qicard','alipay'] },
{ title: 'fovar', children: ['facebook', 'tweeter', 'youtube', 'instaram'] }
];
import Sendemail from '@/views/Home/Components/Sendemail';
export default {
    name: 'Footer',
    data() {
        return {
            /**
             * 外部链接
             */
            outerLink: {
                facebook: 'https://www.facebook.com',
                tweeter: 'https://twitter.com/',
                youtube: 'https://www.youtube.com/',
                instaram: 'https://www.instagram.com/'
            },
            /**
             * 内部链接映射
             */
            map: {
                qa: 'qa',
                scoreRule: 'scoreRule',
                map: 'allCategory',
                open: 'applySeller'
            },
            footerMap
        }
    },
    components: {
        Sendemail
    },
    methods: {
        /**
         * 跳转
         */
        goto(key) {
            if (this.map[key]) {
                return this.$router.push({ name: this.map[key] });
            }
            if (this.outerLink[key]) {
                window.open(this.outerLink[key], '_blank');
            }
        }
    },
    computed: {
        info() {
            return this.$store.state.home?.toUs || [];
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

footer {
    background: url('@/assets/imgs/home/footer_small.png') 0 0/100% 100%;
    height: 365px;

    &[ar] {
        direction: rtl;

        .top {
            padding: 47px 70px 0 0;

            .right {
                margin: 2px 70px 0 0;
            }

            .concat {
                margin: 3px 162px 0 0;

                div {
                    &::after {
                        right: 1px;
                    }
                }
            }
        }
    }

    .bottom {
        border-top: 1px rgba($color: #fff, $alpha: .1) solid;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #CCCCCC;
        opacity: 0.6;
        padding-top: 16px;

        span {
            cursor: pointer;
        }

        .copyright {
            margin: 11px 0 9px;
        }

        span+span::before {
            content: '|';
            padding: 0 10px;
        }
    }

    .top {
        width: $cw;
        margin: 0 auto;
        padding: 47px 0 0 70px;
        display: flex;
        color: #ccc;
        height: 272px;

        .logo {
            height: 169px;
        }

        .right {
            margin-left: 70px;
            margin-top: 2px;
            display: flex;

            dl {
                width: 190px;

                &:last-child {
                    width: 80px;
                }

                dt {
                    font-size: 16px;
                    font-weight: 400;
                    padding-bottom: 39px;
                    color: #EEEEEE;
                }

                dd {
                    font-weight: 400;
                    color: #CCCCCC;
                    opacity: 0.6;
                    cursor: pointer;

                    &+dd {
                        margin-top: 24px;
                    }
                }
            }
        }

        .concat {
            margin-left: 162px;
            margin-top: 3px;
            color: inherit;

            div {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                padding-left: 5px;
                position: relative;
                color: #fff;

                &::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    width: 40px;
                    height: 2px;
                    background: #3CB8FB;
                    bottom: -13px;
                    left: 6px;
                }
            }

            ul {
                height: 129px;
                background: url('@/assets/imgs/home/map-mod.png');
                list-style: none;
                margin-top: 32px;
                padding-left: 4px;
                padding-top: 13px;
                font-size: 12px;
                font-weight: 400;
                display: flex;
                flex-direction: column;
                gap: 18px 21px;

                li {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 13px;
                }
            }
        }
    }
}
</style>