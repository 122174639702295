<template>
    <div class="phone-wrapper">
        <a-select :read-only="readonly" :disabled="dis" :options="codes" v-model="value.areaCode" />
        <a-input :read-only="readonly" :maxLength="11" :disabled="dis" :placeholder="ph" v-model="value.tel"
            @change="$emit('change', value)" oninput="value=value.replace(/[^\d]/g,'')" />
    </div>
</template>

<script>
import api from '@/api/detail';
export default {
    name: 'PhoneNumber',
    data() {
        return {
            /**
             * code列表
             */
            list: []
        }
    },
    computed: {
        codes() {
            return this.list.map(c => ({ label: c, value: c }));
        }
    },
    async created() {
        try {
            const list = await api.areaCode();
            this.list = [...new Set(list.map(c => c.mobilePrefix))];
        } catch (error) { }
    },
    props: {
        /**
         * 是否只读
         */
        readonly: Boolean,
        /**
         * 是否禁用
         */
        dis: Boolean,
        /**
         * 配置项
         */
        options: Array,
        /**
         * 值
         */
        value: Object,
        /**
         * placeholder
         */
        ph: String,
    }
}
</script>

<style lang="scss" scoped>
.phone-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    direction: ltr;

    ::v-deep .ant-select {
        width: 200px;

        .ant-select-selection-selected-value {
            color: #333333 !important;
        }
    }
}
</style>