<template>
    <div class="wrapper">
        <div class="box" :ar="$i18n.locale === 'ar'">
            <div class="left">
                <header>
                    <em v-t="'allCategories'"></em>
                    <div @click="$router.push({ name: 'allCategory' })">
                        <span v-t="'viewAll'"></span>
                        <img src="@/assets/imgs/home/arrow-right.png" alt="">
                    </div>
                </header>
                <ul>
                    <li v-for="item in $store.state.home?.categoryTree" :key="item.categoryId"
                        @mouseenter="selectItem = item">
                        <img :src="item.categoryUrl" alt="">
                        <span @click="$router.push({ name: 'category', query: { cid: item.categoryId } })">{{
                            item.categoryName }}</span>
                    </li>
                </ul>
            </div>
            <div class="right">
                <dl v-for="child in selectItem?.childList" :key="child.categoryId">
                    <dt @click.stop="$router.push({ name: 'category', query: { cid: child.categoryId } })">{{
                        child.categoryName }}</dt>
                    <dd v-for="_child in child.childList" :key="_child.categoryId"
                        @click.stop="$router.push({ name: 'category', query: { cid: _child.categoryId } })">{{
                            _child.categoryName }}
                    </dd>
                </dl>
            </div>
        </div>
        <a-carousel :ar="$i18n.locale === 'ar'" autoplay>
            <a :href="item.outLink || item.pcLink" v-for="item in $store.state.home?.adsensesLunbo " :key="item.adsenseId"
                :target="item.outLink && '_blank'">
                <img :src="item.adsenseUrl" alt="">
            </a>
        </a-carousel>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    data() {
        return {
            /**
             * 选中的项
             */
            selectItem: {}
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.wrapper {
    position: relative;
    min-height: 500px;
    width: $cw;
    margin: 10px auto 0;

    .box {
        position: absolute;
        width: $cw;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        pointer-events: none;

        .right {
            position: absolute;
            left: 280px;
            top: 0;
            z-index: 99;
            width: 920px;
            height: 100%;
            background: #FFFFFF;
            box-shadow: 10px 0 24px 13px rgba(88, 88, 88, 0.2);
            border-radius: 0px 10px 10px 0px;
            cursor: auto;
            display: none;
            margin-left: -1px;
            padding-left: 42px;
            padding-top: 32px;
            color: #333333;
            pointer-events: auto;
            overflow-y: auto;

            &:hover {
                display: block;
            }

            dl {
                overflow: hidden;
                cursor: pointer;
                font-size: 14px;

                &+dl {
                    margin-top: 10px;
                }

                dt {
                    font-weight: bold;
                    padding-bottom: 20px;
                }

                dd {
                    width: 84px;
                    float: left;
                    margin: 0 30px 10px 0;
                }
            }
        }

        &[ar] {
            direction: rtl;

            .right {
                left: unset;
                right: 280px;
                margin: 0 -1px 0 0;
                padding: 32px 42px 0 0;
                box-shadow: 10px 0 24px 13px rgba(88, 88, 88, 0.2);
                border-radius: 10px 0px 0px 10px;
                margin-left: -1px;

                dl {
                    dd {
                        float: right;
                        margin: 0 0 20px 50px;
                    }
                }
            }

            .left {
                header {
                    padding: 0 20px 0 36px;

                    div {
                        span {
                            padding: 0 0 0 6px;
                        }

                        img {
                            transform: rotate(180deg);
                        }
                    }
                }

                ul {
                    li {
                        padding: 0 20px 0 0;

                        img {
                            margin: 0 0 0 11px;
                        }
                    }
                }
            }
        }

        .left {
            width: 280px;
            height: 100%;
            background: #FFFFFF;
            box-shadow: -2px 4px 24px 0px rgba(88, 88, 88, 0.18);
            border-radius: 6px;
            pointer-events: auto;

            &:has(ul:hover)+.right {
                display: block;
            }

            ul {
                max-height: 443px;
                overflow: auto;

                li {
                    height: 38px;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    color: #000000;
                    cursor: pointer;
                    position: relative;

                    &:hover {
                        &>span {
                            color: #279EF4;
                        }

                        .pannel {
                            display: block;
                        }
                    }

                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 11px;
                    }

                    span+span::before {
                        content: '/';
                        padding: 0 13px;
                    }
                }
            }

            header {
                height: 58px;
                background: rgba($color: #F2F3F8, $alpha: .5);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 36px 0 20px;

                em {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    font-style: normal;
                }

                div {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    span {
                        font-size: 12px;
                        font-weight: 300;
                        color: #666666;
                        padding-right: 6px;
                    }
                }
            }
        }
    }

    ::v-deep .ant-carousel {
        width: $cw - 300px;
        margin-left: 300px;

        &[ar] {
            margin: 0 300px 0 0;
        }

        a {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .slick-slide {
            text-align: center;
            height: 500px;
            overflow: hidden;

            &>div {
                height: 100%;
            }
        }

        .slick-dots {
            .slick-active {
                width: 40px;
                height: 8px;
                background: #F5F5F5;
                border-radius: 4px;
            }

            button {
                opacity: 1;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }
        }
    }
}
</style>