/**
 * 公共请求文件
 */

import axios from 'axios';
import { message } from 'ant-design-vue';
import store from '@/store';
import { languageKey } from '@/i18n';
export const keyIndex = ['zh', 'en', 'ar'];

const serve = axios.create({
    timeout: process.env.VUE_APP_TIMEOUT,
    baseURL: process.env.VUE_APP_BASE_URL,
});

serve.interceptors.request.use(config => {
    const { showType } = config.headers;
    store.getters.token && (config.headers.token = store.getters.token);
    const key = window.localStorage.getItem(languageKey);
    if (showType === undefined || showType === null) {
        config.headers.showType = keyIndex.indexOf(key);
    }
    config.headers.coinType = store.state.currentCurrency?.baseSetId;
    return config;
}, error => Promise.reject(error));

serve.interceptors.response.use(response => {
    const { code, message: msg, data } = response.data;
    if ((code ?? 200) !== 200) {
        message.error(msg);
        if (code == 4848) {
            store.dispatch('exit');
        }
        return Promise.reject(code);
    }
    !response.config.no && msg && message.success(msg);
    const { total, records } = data || {};
    if (records && typeof total === 'number') {
        return { total, data: records };
    }
    return data;
}, error => {
    const msg = (error + '').toLowerCase();
    if (msg.includes('timeout')) {
        message.error('请求超时，请检查网络是否畅通');
        return Promise.reject(error);
    }
    if (msg.includes('network error')) {
        message.error('请求失败，请检查您的网络是否正常');
        return Promise.reject(error);
    }
    const { status } = error.response;
    switch (status) {
        case 401:
            message.error('身份校验失败，请重新登录');
            store.dispatch('exit');
            break;
        case 403:
            message.error('您没权限访问此接口，或您的账号已被禁用');
            break;
        case 404:
            message.error('您请求的接口不存在');
            break;
        case 500:
            message.error('服务器内部错误');
            break;
        case 400:
            message.error('请求失败，请检查您的传参是否正确');
            break;
    }
    return Promise.reject(error);
});

export default serve;