<template>
  <div id="app">
    <ConfigProvider :locale="locale" :auto-insert-space-in-button="false">
      <router-view></router-view>
    </ConfigProvider>
  </div>
</template>

<script>
import { ConfigProvider } from 'ant-design-vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/es/locale/en_US';
import ar from 'ant-design-vue/es/locale/ar_EG';

const l = localStorage.getItem('System.locale') || 'zh';
export default {
  name: 'App',
  watch: {
    '$i18n.locale': {
      handler(value) {
        document.title = this.$t('title');
        localStorage.setItem('System.locale', value);
        if (['category', 'allStore', 'allBrand', 'storeHome'].includes(this.$route.name)) {
          let path = this.$route.path;
          if (this.$route.params?.name) {
            path = path.replace(/\/[^\/]+$/, '');
          }
          let { cid } = window;
          !cid && (cid = this.$route.query.cid);
          cid && (path += '?cid=' + cid);
          return window.location.href = path;
        }
        window.location.reload();
      }
    }
  },
  data() {
    return {
      locale: l === 'zh' ? zhCN : l === 'ar' ? ar : enUS
    }
  },
  components: {
    ConfigProvider,
  }
}
</script>