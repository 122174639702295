<template>
        <router-link class="card" tag="div" :to="{ name: 'goodsDetail', params: { commodityId: goods?.commodityId } }">
        <Checkbox v-model="goods.checked" />
        <div class="pic">
            <img :src="splitImage(goods?.commodityUrls)" alt="">
        </div>
        <div class="title">{{ goods?.commodityName }}</div>
        <footer>
            <div>
                <Price :price="goods?.salsePrice" :markSize="20" :priceSize="24" />
            </div>
            <img @click.stop="collect" src="@/assets/imgs/home/uncollect.png" alt="">
        </footer>
    </router-link>
</template>

<script>
import api from '@/api/detail';

/**
 * 可选中商品卡片
 */
export default {
    name: 'CheckGoodsCard',
    methods: {
        /**
         * 收藏商品
         */
        async collect() {
            try {
                await api.commodityCollect({
                    commodityId: this.goods?.commodityId
                });
                this.$store.dispatch('getInfoCounts');
                this.$emit('change');
            } catch (error) { }
        }
    },
    props: {
        /**
         * 商品信息
         */
        goods: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.card {
    width: 285px;
    height: 430px;
    background: #FFFFFF;
    border-radius: 6px;
    position: relative;
    border: 1px solid #fff;
    transition: border .2s linear, box-shadow .2s linear;
    display: inline-block;

    &[active],
    &:hover {
        border: 1px solid #41A7F3;
        box-shadow: -1px 1px 7px 0px rgba(65, 167, 243, 0.32);
    }

    ::v-deep .ant-checkbox-wrapper {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .pic {
        height: 285px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;

        img {
            width: 80%;
            max-height: 80%;
        }
    }

    .title {
        padding-left: 17px;
        padding-right: 15px;
        font-size: 18px;
        color: #333333;
        line-height: 32px;
        height: 65px;
        @include line(2);
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 20px 0 20px;

        img {
            cursor: pointer;
        }
    }
}
</style>