<template>
    <div class="search">
        <div class="box main" :ar="$i18n.locale === 'ar'">
            <img @click="$router.push({ name: 'home' })" src="@/assets/logo.gif" alt="" class="logo">
            <!-- <video src="@/assets/logo.mp4" autoplay :controls="false" loop style="width: 110px; height: 106px;" ></video> -->
            <main>
                <div class="top">
                    <div class="search-box" :ar="$i18n.locale === 'ar'">
                        <a-select v-model="option"
                            :style="{ width: $i18n.locale === 'en' ? '80px' : $i18n.locale === 'zh' ? '60px' : '120px' }"
                            :options="['goods', 'store', 'brand'].map(key => ({ label: $t(key), value: key }))" />
                        <div class="split"></div>
                        <input type="text" maxlength="50" @keyup.enter="search" :placeholder="$t('searchPh')"
                            v-model="value">
                        <span class="btn-search" v-t="'search'" @click="search"></span>
                    </div>
                    <div class="keys">
                        <template v-for="(item, index) in list">
                            <i v-if="index > 0" :key="item + '-i'"></i>
                            <span :key="item" @click="() => { value = item; search(); }">{{ item }}</span>
                        </template>
                    </div>
                </div>
                <ul>
                    <li @click=" $router.push({ name: item === 'sellerCenter' ? 'applySeller' : item })"
                        :active="item === $route.name"
                        v-for="item in ['home',  'allStore', 'coupon', 'partyEntry', 'sellerCenter']            "
                        :key="item" v-t="item"></li>
                </ul>
            </main>
            <section>
                <div @click=" $router.push({ name: 'wishlist' })">
                    <span>
                        <img src="@/assets/imgs/home/heart_small.png" alt="">
                        <em>{{ infoCounts?.wishCount || 0 }}</em>
                    </span>
                    <p v-t="'wish'"></p>
                </div>
                <div @click=" $router.push({ name: 'collection' })">
                    <span>
                        <img src="@/assets/imgs/home/collect_small.png" alt="">
                        <em>{{ infoCounts?.collectCount || 0 }}</em>
                    </span>
                    <p v-t="'collect'"></p>
                </div>
                <div @click=" $router.push({ name: 'cart' })">
                    <span>
                        <img src="@/assets/imgs/home/cart_small.png" alt="">
                        <em>{{ infoCounts?.carCount || 0 }}</em>
                    </span>
                    <p v-t="'cart'"></p>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const storage = window.localStorage;
const key = 'SEARCH_KEY';

export default {
    name: 'Search',
    data() {
        const list = JSON.parse(storage.getItem(key) || '[]');
        return {
            /**
             * 选项
             */
            option: 'goods',
            /**
             * 存储器
             */
            storage,
            /**
             * 搜索列表
             */
            list,
            /**
             * 搜索值
             */
            value: ''
        }
    },
    methods: {
        /**
         * 搜索
         */
        search() {
         
            const v = this.value?.trim();
            if (v && !this.list.includes(v)) {
                this.list.length >= 5 && this.list.pop();
                this.list.unshift(v);
                storage.setItem(key, JSON.stringify(this.list));
            }
         
            if (this.option === 'brand') {
                this.$router.push('/brand/all/' + v);
            }
            else if (this.option === 'goods') {
                this.$router.push('/category/' + v);
            }
            else if (this.option === 'store') {
                this.$router.push('/store/all/' + v);
            }
        }
    },
    watch: {
        '$route.path'() {
            !Object.keys(this.$route.params).length && (this.value = '')
        }
    },
    computed: {
        ...mapState(['infoCounts'])
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.search {
    height: 132px;
    background: #fff;
    padding: 13px 0;
    display: flex;
    box-shadow: 0px 1px 0px 0px #F2F2F2;
    margin-bottom: 1px;

    .box {
        height: 100%;
        margin-top: 2px;
        display: flex;

        &[ar] {
            direction: rtl;
        }

        .logo {
            cursor: pointer;
        }

        section {
            display: flex;
            color: #333333;
            padding-top: 4px;
            gap: 43px;

            &>div {
                position: relative;
                text-align: center;
                cursor: pointer;

                span {
                    display: inline-block;
                    position: relative;

                    em {
                        position: absolute;
                        right: 0px;
                        top: 2px;
                        min-width: 14px;
                        height: 14px;
                        background: #E60013;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 14px;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 500;
                        font-style: normal;
                    }
                }

                p {
                    padding-top: 7px;
                    white-space: nowrap;
                    color: #000;
                    text-align: center;
                }
            }
        }

        main {
            margin: 0 60px;

            ul {
                display: flex;
                list-style: none;
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                padding-top: 25px;
                font-family: middle;
                justify-content: space-between;

                li {
                    cursor: pointer;

                    &[active] {
                        font-weight: bold;
                        color: #279EF4;
                        font-family: bold;
                    }
                }
            }

            .top {
                .search-box {
                    width: 715px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 2px solid #38A3F1;
                    border-radius: 19px;
                    display: flex;
                    align-items: center;
                    color: #333333;
                    padding: 0 5px 0 22px;
                    font-weight: 500;

                    &[ar] {
                        padding: 0 22px 0 5px;
                    }

                    ::v-deep .ant-select {
                        .ant-select-selection {
                            border: none;
                            box-shadow: none;
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .ant-select-arrow {
                                margin: 0;
                                position: static;
                            }

                            .ant-select-selection__rendered {
                                padding: 0;
                                margin: 0;

                                .ant-select-selection-selected-value {
                                    line-height: 30px;
                                    font-family: middle;
                                    padding-right: 5px;
                                }
                            }
                        }
                    }

                    img {
                        margin-left: 8px;
                    }

                    .split {
                        width: 1px;
                        height: 20px;
                        background: #F3F0F0;
                        margin: 0 18px 0 7px;
                    }

                    input[type='text'] {
                        border: none;
                        outline: none;
                        flex: 1;
                    }

                    .btn-search {
                        width: 72px;
                        height: 30px;
                        background: linear-gradient(0deg, #38A3F1 0%, #8ECEFD 100%);
                        border-radius: 15px;
                        line-height: 29px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }

            .keys {
                padding-left: 4px;
                padding-top: 11px;
                font-weight: 400;
                min-height: 25px;
                color: #999999;
                display: flex;
                align-items: center;
                max-width: 715px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                    cursor: pointer;
                }

                i {
                    width: 1px;
                    height: 14px;
                    background: #999;
                    margin: 0 10px;
                }
            }
        }
    }
}
</style>