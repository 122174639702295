<template>
    <div>
        <Input :max="30" v-model.trim="model.password" :prefix="require('@/assets/imgs/users/密码.png')" type="password"
            :ph="$t('passwordPh')" />
        <Input :max="30" v-model.trim="model.confimPassword" :prefix="require('@/assets/imgs/users/密码.png')" type="password"
            :ph="$t('inputPasswordAgain')" />
    </div>
</template>

<script>
import { message } from 'ant-design-vue';

export default {
    name: 'Password',
    data() {
        return {
            /**
             * 对象
             */
            model: {}
        }
    },
    methods: {
        /**
         * 验证数据
         */
        valid() {
            for (const [key, item] of Object.entries({ password: 'password', confimPassword: 'confirmPassword' })) {
                if (!this.model[key]) {
                    message.warn(this.$t('required').replace('{t}', this.$t(item)));
                    return Promise.reject();
                }
            }
            const { password, confimPassword } = this.model;
            if (password !== confimPassword) {
                message.warn(this.$t('passwordNotSame'));
                return Promise.reject();
            }
            return { ...this.model };
        }
    }
}
</script>

<style lang="scss" scoped></style>