<template>
    <AModal :dialogClass="dialogClass" destroyOnClose :maskClosable="false" v-bind="$attrs" :width="width"
        :dialogStyle="{ borderRadius: '10px', height: typeof height === 'string' ? height : height + 'px' }" :title="title"
        v-model="visible" :closable="false" :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }">
        <slot></slot>
        <template v-slot:footer>
            <slot name="footer">
                <Button :style="buttonStyle" @click="visible = false" shape="round" v-if="close">{{ $t('close') }}</Button>
                <Button :style="buttonStyle" @click="visible = false; $emit('cancel')" type="default" active shape="round"
                    v-if="cancel">{{
                        typeof cancel === 'string' ? cancel : $t('cancel')
                    }}</Button>
                <Button :style="buttonStyle" :loading="confirmLoading" @click="$emit('confirm')" shape="round"
                    v-if="confirm">{{ typeof confirm ===
                        'string' ? confirm :
                        $t('ok') }}</Button>
            </slot>
        </template>
    </AModal>
</template>

<script>
import { Modal as AModal } from 'ant-design-vue';
export default {
    name: "Modal",
    components: { AModal },
    data() {
        return {
            /**
             * 是否显示
             */
            visible: false
        }
    },
    methods: {
        /**
         * 显示
         */
        show() {
            this.visible = true;
        },
        /**
         * 隐藏
         */
        hide() {
            this.visible = false;
        }
    },
    computed: {
        /**
         * 
         */
        dialogClass() {
            const names = [];
            this.center && names.push('center');
            this.middle && names.push('middle');
            return names.join(' ');
        }
    },
    props: {
        /**
         * 提交按钮loading
         */
        confirmLoading: Boolean,
        /**
         * 按钮宽度
         */
        buttonStyle: Object,
        /**
         * 垂直居中
         */
        middle: Boolean,
        /**
         * 内容居中
         */
        center: Boolean,
        /**
         * 取消
         */
        cancel: [Boolean, String],
        /**
         * 确定
         */
        confirm: [Boolean, String],
        /**
         * 关闭
         */
        close: Boolean,
        /**
         * 标题
         */
        title: String,
        /**
         * 宽
         */
        width: Number,
        /**
         * 高度
         */
        height: {
            type: [String, Number],
            default: '280px'
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .center {
    .ant-modal-content {
        .ant-modal-body {
            text-align: center;
        }
    }
}

::v-deep .middle {
    .ant-modal-content {
        .ant-modal-body {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}

::v-deep .ant-modal-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    .ant-modal-header {
        border-color: white;
        padding: 29px 0 0;
        border-radius: 10px 10px 0 0;

        .ant-modal-title {
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            font-family: middle;
        }
    }

    .ant-modal-body {
        flex: 1;
        color: #333333;
        font-size: 18px;
        padding: 0;
        line-height: 1;
    }

    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: white;
        padding: 0 0 35px;
        gap: 30px;

        &>.button {
            width: 200px;
            height: 52px;
            border-radius: 26px;
            font-weight: 500;
            font-size: 18px;
            font-family: middle;
        }
    }
}
</style>