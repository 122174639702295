<template>
    <div class="image-uploder-wrapper">
        <div class="img" :style="{ width: wh, height: wh }" v-for="(item, index) in pics" :key="index">
            <img src="@/assets/imgs/home/close_red.png" @click="deletePic(index)" alt="">
            <img :src="item" alt="">
        </div>
        <label :style="{ width: wh, height: wh }" class="plus" v-if="!pics || pics.length < max">
            <input :multiple="max > 1" type="file" @change="change" accept="image/png,image/jpeg,image/gif,image/bmp">
            <img src="@/assets/imgs/home/plus_xl.png" alt="">
            <p v-t="'clickUpload'" v-if="!hideText"></p>
        </label>
    </div>
</template>

<script>
import api from '@/api/other';
/**
 * 获取对象URL
 */
const getObjectURL = file => (window.URL || window.webkitURL || window).createObjectURL(file);
export default {
    name: 'ImageUploder',
    data() {
        return {
            /**
             * 图片列表
             */
            pics: Array.isArray(this.value) ? [...this.value] : []
        }
    },
    methods: {
        /**
         * 删除图片
         * @param {*} index 下标
         */
        deletePic(index) {
            this.pics.splice(index, 1);
            if (!this.value?.length) return;
            const value = [...this.value];
            value.splice(index, 1);
            this.$emit('input', value);
            this.$emit('change', value);
        },
        /**
         * 选择图片
         */
        async change(e) {
            const files = e.target.files;
            if (!files.length) return;
            const imgs = [];    //图片列表
            for (const file of files) {
                const { type, size } = file;
                if (!/^image\//.test(type)) {
                    continue;
                }
                if (size > this.size) {
                    continue;
                }
                const url = getObjectURL(file);
                imgs.push(file);
                this.pics.push(url);
            }
            let list = [];
            try {
                list = await api.upload(imgs);
                this.pics.splice(this.pics.length - list.length, Infinity, ...list);
            } catch (error) {
                this.pics.splice(this.pics.length - imgs.length);
                imgs.length = 0;
            }
            e.target.value = '';
            this.$emit('input', this.pics);
            this.$emit('change', this.pics);
        }
    },
    props: {
        /**
         * 隐藏上传文本
         */
        hideText: Boolean,
        /**
         * 宽高
         */
        wh: String,
        /**
         * 文件大小
         */
        size: Number,
        /**
         * 最多上传的图片
         */
        max: {
            type: Number,
            default: 1
        },
        /**
         * 图片列表
         */
        value: Array,
    }
}
</script>

<style lang="scss" scoped>
.image-uploder-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    div,
    label {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 240px;
        height: 160px;
        background: #F6F6F6;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        position: relative;

        &.plus {
            input {
                display: none;
            }

            p {
                margin: 14px 0 0;
                color: #666666;
            }
        }

        &.img {
            background: #E0DEE1;

            img {
                &:first-child {
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                }

                &:last-child {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>