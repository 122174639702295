<template>
    <div class="pagination-wrapper" :ar="$i18n.locale === 'ar'">
        <a-pagination :style="{ padding }" showQuickJumper :ar="$i18n.locale === 'ar'" :current="value[fields[0]]"
            :pageSize="value[fields[1]]" :show-total="total => $t('total').replace('{total}', pages)" class="pagination"
            :total="value.total" @change="change" />{{ $t('paginationTip') }}
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    methods: {
        /**
         * 分页改变
         */
        change(page, size) {
            const obj = { ... this.value, [this.fields[0]]: page, [this.fields[1]]: size };
            this.$emit('input', obj);
            this.$emit('change', obj);
        }
    },
    computed: {
        /**
         * 总页数
         */
        pages() {
            return Math.ceil(this.value.total / this.value[this.fields[1]]);
        }
    },
    props: {
        /**
         * 字段名
         */
        fields: {
            type: Array,
            default: () => ['pageNumber', 'pageSize']
        },
        /**
         * 值
         */
        value: Object,
        /**
         * margin间距
         */
        padding: String
    }
}
</script>

<style lang="scss" scoped>
.pagination-wrapper {
    text-align: center;

    &[ar] {
        direction: rtl;
    }

    .pagination {
        display: inline-flex;
        gap: 8px;
        align-items: center;
        overflow: hidden;

        &[ar] {
            ::v-deep {
                li {
                    &.ant-pagination-next {
                        i {
                            transform: rotate(180deg);
                        }
                    }

                    &.ant-pagination-prev {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        ::v-deep {
            .ant-pagination-options-quick-jumper {
                margin-right: 8px;
            }

            .ant-select-selection-selected-value {
                line-height: 30px;
            }

            .ant-pagination-item {
                margin: 0;
                border-radius: 0;
                background: rgb(247, 247, 247);
            }

            .ant-pagination-total-text {
                flex-shrink: 0;
            }

            .ant-select-dropdown-menu-item {
                width: 100%;
            }

            .ant-pagination-options {
                width: 100%;
            }

            .ant-pagination-total-text {
                width: auto;
                line-height: 36px;
                color: #333333;
                float: right;
                margin: 0;
                padding-left: 16px;
            }

            li {
                width: 34px;
                height: 34px;
                line-height: 32px;
                border-radius: 2px;
                margin: 0;
            }

            .ant-pagination-item-ellipsis {
                line-height: 34px;
            }

            .ant-pagination-item-link-icon {
                line-height: 38px;
            }

            .ant-pagination-item-active {
                background: none;
                border: none;

                a {
                    color: red;
                }
            }
        }
    }
}
</style>