<template>
    <Category class="category" :title="$t('recommendBrand')" @more="$router.push({ name: 'allBrand' })">
        <div class="box">
            <div class="list">
                <div class="item" v-for="item in list" :key="item.brandId"
                    @click="$router.push({ name: 'brandList', params: { id: item.brandId } })">
                    <img :src="item.brandPic" alt="">
                </div>
            </div>
        </div>
    </Category>
</template>

<script>
export default {
    name: 'Recommand',
    props: {
        list: Array
    }
}   
</script>

<style lang="scss" scoped>
.category {
    margin-bottom: 108px;

    .box {
        padding: 80px 0;
        background: #FFFFFF;
        border-radius: 6px;

        .list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 40px 25px;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                height: 80px;
                background: #FFFFFF;
                box-shadow: -1px 3px 10px 0px rgba(59, 95, 169, 0.09);
                border-radius: 6px;

                img {
                    height: 100%;
                }
            }
        }
    }
}
</style>