<template>
    <a-button :ar="$i18n.locale === 'ar'" :active="active" :class="{ button: true, r21, r23, r26, r28 }"
        :style="{ width, height }" :size="size" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </a-button>
</template>

<script>
import { Button } from 'ant-design-vue';
export default {
    name: 'Button',
    components: {
        'a-button': Button
    },
    props: {
        /**
         * 是否为激活状态
         */
        active: Boolean,
        /**
         * 21度圆角
         */
        r21: Boolean,
        /**
         * 23度圆角
         */
        r23: Boolean,
        /**
         * 26度圆角
         */
        r26: Boolean,
        /**
         * 28度圆角
         */
        r28: Boolean,
        /**
         * 大小
         */
        size: {
            type: String,
            default: 'large'
        },
        /**
         * 宽度
         */
        width: String,
        /**
         * 高度
         */
        height: String
    }
}
</script>

<style lang="scss" scoped>
.button {
    background: linear-gradient(0deg, #38A3F1 0%, #8ECEFD 100%);
    color: #FFFFFF;
    height: 34px;
    font-size: 16px;
    line-height: 34px;
    border: none;
    padding: 0 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &[ar] {
        direction: rtl;
    }

    &.r21 {
        &.ant-btn-round {
            border-radius: 21px;
        }
    }

    &.r23 {
        &.ant-btn-round {
            border-radius: 23px;
        }
    }

    &.r26 {
        &.ant-btn-round {
            border-radius: 26px;
        }
    }

    &.r28 {
        &.ant-btn-round {
            border-radius: 28px;
        }
    }

    &[middle] {
        height: 36px;
    }

    &.ant-btn-default {
        background: transparent;
        border: 1px solid #E7E7E7;
        padding: 0 23px;
        color: #888888;

        &:hover {
            border-color: #1799F7;
            color: #1799F7;
        }
    }

    &.ant-btn-lg {
        padding: 0 22px;
        height: 42px;
        font-size: 18px;
        font-weight: 500;
    }

    &.ant-btn-round {
        border-radius: 17px;
    }

    &[active] {
        border: 1px solid #1799F7;
        color: #1799F7;
    }
}
</style>