import Vue from "vue";
import Vuex from "vuex";
import api from "@/api/home";
import table from "@/api/table";
import router from "@/router";
import user from "@/api/user";

Vue.use(Vuex);

const storePersist = (store) => {
  /**
   * 存储的key
   */
  const key = "VUEX_PERSIST_ZAYYA";
  /**
   * 存储器
   */
  const storage = window.sessionStorage;
  /**
   * 本地存储
   */
  const local = window.localStorage;
  /**
   * 已存储的数据
   */
  const data = storage.getItem(key);
  /**
   * 本地存储的数据
   */
  const localData = local.getItem(key);

  Object.assign(store.state, JSON.parse(data), JSON.parse(localData));

  data && store.replaceState(store.state);

  /**
   * 注册mutations的回调函数
   */
  store.subscribe(({ type, payload }, state) => {
    if (type === "setCurrentCurrency") {
      local.setItem(
        key,
        JSON.stringify({ currentCurrency: payload, user: state.user })
      );
    } else if (type === "setUser") {
      local.setItem(
        key,
        JSON.stringify({
          currentCurrency: state.currentCurrency,
          user: payload,
        })
      );
    } else {
      storage.setItem(
        key,
        JSON.stringify({
          ...state,
          currentCurrency: undefined,
          user: undefined,
        })
      );
    }
  });
};

export default new Vuex.Store({
  state: {
    /**
     * 地址ID
     */
    addressId: "",
    /**
     * 商铺
     */
    shop: {},
    /**
     * 订单信息
     */
    order: [],
    /**
     * 申请信息
     */
    applyInfo: {},
    /**
     * 相关优惠券
     */
    coupon: {},
    /**
     * 心愿单等数量
     */
    infoCounts: {},
    /**
     * 首页数据
     */
    home: {},
    /**
     * 个人数量信息
     */
    userCounts: {},
    /**
     * 用户信息
     */
    user: {},
    /**
     * 货币列表
     */
    currency: [],
    /**
     * 当前货币
     */
    currentCurrency: {},
    /**
     * 为true时切换样式返回店铺下商品信息
     */
    showMark: true,
    /**
     * 临时用户ID
     */
    tmpUserId: "",
    /**
     * wish
     */
    wish: "",
    /**
     * 优惠券id
     */
    discountId: "",
    /**
     * 用户数量
     */
    userCounts: {},
  },
  getters: {
    /**
     * token
     * @param {*} state
     * @returns
     */
    token(state) {
      return state.user.token;
    },
  },
  mutations: {
    /**
     * 设置用户数量
     */
    setUserCounts(state, payload) {
      state.userCounts = payload;
    },
    /**
     * 设置临时用户ID
     */
    setTmpUserId(state, payload) {
      state.tmpUserId = payload;
    },
    /**
     * 存储wish
     */
    setWish(state, payload) {
      state.wish = payload;
    },
    resetWish(state) {
      state.wish = '';
    },
    /**
     * 设置店铺样式
     */
    setShowMark(state, payload) {
      state.showMark = payload;
    },
    /**
     * 设置当前货币
     */
    setCurrentCurrency(state, payload) {
      state.currentCurrency = payload;
    },
    /**
     * 设置货币列表
     */
    setCurrency(state, payload) {
      state.currency = payload;
    },
    /**
     * 设置地址id
     */
    setAddressId(state, payload) {
      state.addressId = payload;
    },
    /**
     * 设置商铺信息
     */
    setShop(state, payload) {
      state.shop = payload;
    },
    /**
     * 设置订单
     */
    setOrder(state, payload) {
      state.order = payload;
    },
    /**
     * 设置申请信息
     */
    setApplyInfo(state, payload) {
      state.applyInfo = payload;
    },
    /**
     * 设置优惠券
     */
    setCoupon(state, payload) {
      state.coupon = payload;
    },
    /**
     * 设置心愿单等数量
     */
    setInfoCounts(state, payload) {
      state.infoCounts = payload;
    },
    /**
     * 设置首页信息
     */
    setHome(state, payload) {
      state.home = payload;
    },
    /**
     * 设置个人数量信息
     */
    setUserCounts(state, payload) {
      state.userCounts = payload;
    },
    /**
     * 设置用户
     */
    setUser(state, payload) {
      state.user = payload;
    },
    /**
     * 设置优惠券id
     */
    setDiscountId(state, payload) {
      state.discountId = payload;
    },
  },
  actions: {
    /**
     * 获取用户数量
     */
    async getUserCounts({ commit, getters }) {
      try {
        if (!getters.token) return;
        const data = await user.userCounts();
        commit("setUserCounts", data);
      } catch (error) {}
    },
    /**
     * 获取货币列表
     */
    async getCurrency({ commit, state }) {
      try {
        const list = await api.currency();
        if (!state.currentCurrency?.tittle) {
          commit("setCurrentCurrency", list[0]);
        }
        commit("setCurrency", list);
      } catch (error) {}
    },
    /**
     * 获取申请信息
     */
    async getApplyInfo({ commit, getters }) {
      if (!getters.token) return commit("setApplyInfo", {});
      try {
        const obj = await table.getBusinessInfo();
        commit("setApplyInfo", obj);
      } catch (error) {}
    },
    /**
     * 获取心愿单等数量
     */
    async getInfoCounts({ commit, getters }) {
      try {
        const obj = await api.infoCounts();
        commit("setInfoCounts", obj);
      } catch (error) {}
    },
    /**
     * 获取首页数据
     */
    async getHome({ commit }) {
      try {
        const obj = await api.index();
        commit("setHome", obj);
      } catch (error) {}
    },
    /**
     * 退出登录
     */
    exit({ commit }) {
      commit("setUser", {});
      commit("setTmpUserId", "");
      commit("setInfoCounts", {});
      commit("setApplyInfo", {});
      router.replace({ name: "home" });
    },
  },
  plugins: [storePersist],
});
