<template>
    <Category :hideMore="hideMore" :title="title" @more="$router.push({ name: 'category', query: { cid } })">
        <div class="list" :ar="$i18n.locale === 'ar'">
            <img :src="splitPic(list[0]?.commodityUrls)"
                @click="$router.push({ name: 'goodsDetail', params: { commodityId: list[0]?.commodityId } })" />
            <HomeGoodsCard v-for="item in  renderList " :goods="item" :key="item.commodityId" />
        </div>
    </Category>
</template>

<script>
export default {
    name: 'HomeGoodsList',
    props: {
        /**
         * 分类ID
         */
        cid: Number,
        /**
         * 隐藏更多
         */
        hideMore: Boolean,
        /**
         * 标题
         */
        title: String,
        /**
         * 商品列表
         */
        list: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        /**
         * 分割图片
         */
        splitPic(str) {
            return (str?.split(',') || [])[0];
        }
    },
    computed: {
        /**
         * 剩余的列表
         */
        renderList() {
            return this.list?.slice(1) || [];
        }
    }
}
</script>

<style lang="scss" scoped>
.list {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;

    &[ar] {
        direction: rtl;
    }

    &>img {
        width: 470px;
        height: 405px;
        object-fit: cover;
        cursor: pointer;
    }
}
</style>