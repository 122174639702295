<!--
 * @Description: 
 * @Version: Do not edit
 * @Autor: qianzhengc
 * @Date: 2023-03-29 20:21:31
 * @LastEditors: qianzhnegc
 * @LastEditTime: 2023-03-29 20:21:57
-->
<template>
    <div class="priceshow" :style="{ fontSize: priceSize + 'px', color }" :ar="$i18n.locale === 'ar'">
        <em :style="{ fontSize: markSize + 'px', fontFamily: markBold ? 'bold' : 'Source Han Sans CN' }"> {{
            $store.state.currentCurrency?.tittle }}</em>{{ p }}
    </div>
</template>

<script>
/**
 * 价格组件
 */
export default {
    name: 'Price',
    computed: {
        p() {
            const num = Number(this.price);
            if (Object.is(num, NaN)) {
                return this.price;
            }
            return num.toFixed(2).replace(/\.0+$/, '');
        }
    },
    props: {
        /**
         * 字体颜色
         */
        color: {
            type: String,
            default: '#E60013'
        },
        /**
         * 标记是否为粗体
         */
        markBold: {
            type: Boolean,
            default: true
        },
        /**
         * 钱标志大小
         */
        markSize: {
            type: Number,
            default: 16
        },
        /**
         * 价格大小
         */
        priceSize: {
            type: Number,
            default: 22
        },
        /**
         * 价格
         */
        price: [String, Number]
    }
}
</script>

<style lang="scss" scoped>
div {
    font-family: bold;
    align-items: flex-end;
    gap: 5px;
    white-space: nowrap;
    padding-left: 5px;
    &[ar] {
        direction: rtl;
    }

    em {
        font-style: normal;
        padding-right: 3px;
    }
}
</style>