<!--
 * @Description: 
 * @Version: Do not edit
 * @Autor: qianzhengc
 * @Date: 2023-03-29 20:21:31
 * @LastEditors: qianzhnegc
 * @LastEditTime: 2023-03-29 20:21:47
-->
<template>
    <div class="stars-wrapper">
        <img :width="width" :height="width" :src="index <= score ? red : gray" alt="" v-for="index in 5" :key="index" />
    </div>
</template>

<script>
/**
 * 红色星星
 */
const red = require('@/assets/imgs/home/star_red.png');
/**
 * 灰色星星
 */
const gray = require('@/assets/imgs/home/star_gray.png');
export default {
    name: 'Stars',
    data() {
        return {
            red,
            gray
        }
    },
    props: ['score', 'width']
}
</script>

<style lang="scss" scoped>
.stars-wrapper {
    display: flex;
    gap: 10px;
}
</style>