<template>
    <Modal :width="700" ref="modal" cancel confirm :confirmLoading="confirmLoading" @confirm="confirm">
      <div class="email-content">
        <div class="point_input">
            <div style="width: 140px;">{{$t('username')}}：</div>
            <a-input type="text" style="width: 350px;" v-model="name" :placeholder="$t('username')"></a-input>
        </div>
        <div class="point_input">
            <div style="width: 140px;">{{$t('email')}}：</div>
            <a-input type="text" style="width: 350px;" v-model="email" :placeholder="$t('email')"></a-input>
        </div>
        <div class="point_input">
            <div style="width: 140px;">{{$t('Contactnumber')}}：</div>
            <a-input type="text" style="width: 350px;" v-model="phone" :placeholder="$t('Contactnumber')" ></a-input>
        </div>
        <div class="point_input">
            <div style="width: 140px;">{{$t('Message')}}：</div>
            <a-textarea  style="width: 350px; height: 100px;" v-model="content" :placeholder="$t('Message')"  ></a-textarea>
        </div>
      </div>
    </Modal>
  </template>
  <script>
  import { message } from 'ant-design-vue';
  import api from '@/api/user';
  export default {
    data() {
      return {
        /**
         * 密码
         */
        password: '',
        /**
         * 提交按钮loading
         */
        confirmLoading: false,
        /**
         * 激活标签下标
         */
        active: 0,

      
        email:'',
        phone:'',
        content:'',
        name:'',
      }
    },
    props: ['currepoint'],
    methods: {
      /**
       * 确定
       */
       replaspoint(text){
        return text.replace('***', this.doorsill);
       },
      async confirm() {
        var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if(!emailRegex.test(this.email)){
            message.warn(this.$t('Pleaseenterthecorrectemailaddress'));
            return
        }
        if(this.email==''||this.phone==''||this.content==''){
            message.warn(this.$t('Pleasefillintheinformationandsubmit'));
            return
        }
        var params={
            mailTo: this.email,
            name:this.name,
            tel:this.phone,
            content:this.content,
        }
        try {
         const res = await api.problemFeedback(params)
         console.log(res)
         message.success(this.$t('sendsuccess'));
         this.hide()
        } catch (error) { }
        this.confirmLoading = false;
      },
      /**
       * 显示
       */
      show() {
        this.password = '';
        this.$refs.modal.show();
      },
      /**
       * 隐藏
       */
      hide() {
        this.$refs.modal.hide();
      },
      changeall(){
        this.money = this.currepoint*this.ratio 
        this.point = this.currepoint
      },
      changemoeny(e){
        if(this.point>this.currepoint){
            message.warn(this.$t('nothaveenoughpoints'));
            this.point = ''
            this.money = 0
        }else{
            this.money = this.point*this.ratio 
        }
        
        console.log(this.point)
      },
      async getRule(){
        
        try {
         const {doorsill,ratio} = await api.pointRule()
         this.doorsill = doorsill
         this.ratio = ratio
        } catch (error) { }
        console.log(1111111)
      }
    },
    created(){
        this.getRule()
    },
    computed: {
      /**
      * 是否显示按钮
      */
      showButton() {
        return (this.active == 0 && this.user.mailAddress) || (this.active == 1 && this.user.tel);
      },
      /**
       * 用户信息
       */
      user() {
        return this.$store.state?.user;
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .email-content {
    padding: 50px 70px;
    font-size: 16px;
    .nowpoints{
        line-height: 40px;
        span{
            color: #279EF4;
            font-size: 20px;
        }
    }
    .point_input{
        display: flex;
        align-items: center;
        margin-top: 10px;
        input{
            height: 40px;
            border: 1px solid #eee;
            border-radius: 5px;
        }
        .alldui{
            color: #279EF4;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .kduihuan{
        text-align: center;
        line-height: 40px;
        margin-top: 20px;
        span{
            color: #279EF4;
            font-size: 20px;
        }
    }
    .tips{
        margin-top: 40px
    }
  }
  </style>
  