<template>
   
        <router-link tag="div" class="card" :style="{ width: width + 'px' }"
        :to="{ name: 'goodsDetail', params: { commodityId: goods.commodityId } }">
        <div class="pic">
            <img :style="{ height: width && width + 'px' }" :src="pic" alt="">
        </div>
        <div class="bottom">
            <div class="title">{{ goods?.commodityName }}</div>
            <footer>
                <div>
                    <Price :price="goods?.salsePrice" :markSize="20" :priceSize="24" />
                </div>
                <img @click.stop="collect" :src="!goods?.collectMark ? collected : unCollect" alt="">
            </footer>
        </div>
    </router-link>
</template>

<script>
import api from '@/api/detail';

export default {
    name: 'HomeGoodsCard',
    data() {
        return {
            /**
             * 已收藏图标
             */
            collected: require('@/assets/imgs/home/collect.png'),
            /**
             * 未收藏图标
             */
            unCollect: require('@/assets/imgs/home/uncollect.png')
        }
    },
    props: {
        /**
         * 宽度
         */
        width: Number,
        /**
         * 商品信息
         */
        goods: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * 图片
         */
        pic() {
            return (this.goods?.commodityUrls?.split(',') || [])[0];
        }
    },
    methods: {
        /**
         * 收藏商品
         */
        async collect() {
            try {
                await api.commodityCollect({
                    commodityId: this.goods?.commodityId
                });
                this.$store.dispatch('getInfoCounts');
                this.goods.collectMark = !this.goods.collectMark;
            } catch (error) { }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.card {
    width: 225px;
    padding-bottom: 10px;
    background: #FFFFFF;
    border-radius: 6px;

    .pic {
        img {
            width: 100%;
            height: 225px;
            object-fit: cover;
        }
    }

    .bottom {

        .title {
            padding: 10px 15px 30px;
            height: 66px;
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            @include line(2);
        }

        footer {
            padding: 21px 20px 0 20px;

            img {
                margin-top: 23px;
                cursor: pointer;
            }
        }
    }
}
</style>