<template>
    <div class="user-layout-wrapper" :ar="$i18n.locale === 'ar'">
        <header>
            <div class="main">
                <router-link :to="{ name: 'home' }">
                    <img src="@/assets/logo_users.png" alt="">
                </router-link>
                <div>
                    <span v-t="have ? 'hadAccount' : 'noAccount'"></span>
                    <Button v-on="$listeners" v-t="have ? 'gotoLogin' : 'gotoRegister'" type="default" active
                        size="small"></Button>
                </div>
            </div>
        </header>
        <main>
            <div class="form">
                <img :src="pic" alt="">
                <div
                    :style="{ height: typeof height === 'string' ? height : height + 'px', width: typeof width === 'string' ? width : width + 'px' }">
                    <h1 v-t="'welcomeShort'"></h1>
                    <slot></slot>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import Footer from '../MainLayout/components/Footer.vue';
export default {
    name: 'UserLayout',
    props: {
        /**
         * 图片
         */
        pic: {
            type: String,
            default: require('@/assets/imgs/users/740x640.png')
        },
        /**
         * 宽度
         */
        width: [Number, String],
        /**
         * 高度
         */
        height: {
            type: [String, Number],
            default: 640
        },
        /**
         * 已有账号
         */
        have: Boolean
    },
    components: { Footer }
}
</script>

<style lang="scss" scoped>
.user-layout-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &[ar] {
        direction: ltr;

        main {
            .form {
                &>div {
                    direction: rtl;
                }
            }
        }

        header {
            .main {
                direction: rtl;

                &>div {
                    gap: 38px;
                }
            }
        }
    }

    main {
        flex: 1;
        background: url(@/assets/imgs/users/bg.png) 0 0/100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 56px 0;

        .form {
            display: flex;
            align-items: center;

            &>div {
                background: #FFFFFF;
                box-shadow: 0px 6px 13px 0px rgba(218, 230, 255, 0.4), 4px -5px 6px 0px rgba(190, 212, 255, 0.08);
                border-radius: 0px 10px 10px 0px;
                padding: 50px 20px 0;

                h1 {
                    font-size: 26px;
                    font-family: middle;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }
    }

    header {
        height: 80px;
        background: #FFFFFF;
        box-shadow: 1px 4px 13px 0px rgba(230, 230, 230, 0.44);

        .main {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                width: 62px;
                height: 60px;
            }

            &>div {
                display: flex;
                align-items: center;

                span {
                    font-size: 18px;
                    color: #333333;
                    margin-right: 38px;
                }

                button {
                    display: flex;
                    align-items: center;
                    height: 32px;
                    border-radius: 2px;
                    font-size: 16px;
                    font-family: middle;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>