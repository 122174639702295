<template>
    <div class="wrapper">
        <img @click="slide(index + 1)" src="@/assets/imgs/home/组 3.png" alt="" v-if="length > 4">
        <img @click="slide(index - 1)" src="@/assets/imgs/home/组 3.png" alt="" v-if="length > 4">
        <div class="box" ref="box" :style="{ width: total * 1224 + 'px', transform: `translateX(-${1224 * index}px)` }">
            <div v-for="item in $store.state.home?.adsensesKuaijie" :key="item.adsenseId">
                <a :href="item.outLink || item.pcLink || 'javascript:void(0)'" :target="item.outLink && '_blank'">
                    <img :src="item.adsenseUrl" alt="" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    data() {
        return {
            /**
             * 下标
             */
            index: 0
        }
    },
    computed: {
        /**
         * 长度
         */
        length() {
            return this.$store.state.home?.adsensesKuaijie?.length || 0;
        },
        /**
         * 总页数
         */
        total() {
            return Math.ceil(this.length / 4);
        }
    },
    methods: {
        /**
         * 滑动
         */
        slide(index) {
            this.index = Math.min(this.total - 1, Math.max(0, index));
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;

    &>img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 10;
        cursor: pointer;

        &+img {
            left: unset;
            right: 0;
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .box {
        display: flex;
        transition: transform .5s linear;

        div {
            width: 281px;
            height: 175px;
            background: #FFFFFF;
            border-radius: 4px;

            img {
                width: 100%;
                height: 100%;
            }

            &+div {
                margin-left: 25px;
            }
        }
    }
}
</style>