/**
 * 其他接口文件
 */

import request from './request';

export default {
    /**
     * 上传
     */
    upload: files => {
        const form = new FormData();
        files.forEach(file => {
            form.append(`multipartFiles`, file);
        });
        return request.post('/public/uploadfiles', form, {
            'Content-Type': 'multipart/form-data'
        }, { no: true });
    },
}