<template>
    <div class="input-wrapper" :ar="$i18n.locale === 'ar'">
        <img v-if="prefix" :src="prefix" alt="">
        <div class="content-wrapper" :style="{ direction }">
            <div class="prefix" v-if="$slots.prefix">
                <slot name="prefix"></slot>
            </div>
            <input :maxlength="max" :readonly="readonly" :value="value" @input="change" :type="type" :placeholder="ph"
                :style="{ 'text-align': $i18n.locale == 'ar' ? 'right' : '' }">
            <div class="suffix" v-if="$slots.default">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Input',
    methods: {
        /**
         * 值更改
         */
        change(e) {
            if (this.type === 'number') {
                const { value } = e.target;
                if (value.length > this.max) {
                    e.target.value = value.slice(0, this.max);
                }
            }
            this.$emit('input', e.target.value);
        }
    },
    props: {
        /**
         * 布局方向
         */
        direction: {
            type: String,
            default: 'inherit'
        },
        /**
         * 是否只读
         */
        readonly: Boolean,
        /**
         * 最大长度
         */
        max: Number,
        /**
         * 值
         */
        value: String,
        /**
         * 前缀
         */
        prefix: String,
        /**
         * 类型
         */
        type: String,
        /**
         * placeholder
         */
        ph: String
    }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    display: flex;
    height: 62px;
    background: #F7F8FA;
    border-radius: 4px;
    align-items: center;
    padding: 0 10px;
    gap: 5px;

    &[ar] {
        direction: rtl;
    }

    &+.input-wrapper {
        margin-top: 25px;
    }

    .content-wrapper {
        display: flex;
        flex: 1;
        align-items: center;

        input {
            height: 100%;
            width: 100%;
            font-size: 18px;
            color: #333;
            border: none;
            outline: none;
            background: transparent;

            &::placeholder {
                color: #999999;
            }
        }

        .prefix {
            border-right: 1px solid #EEEEEE;
        }

        .suffix {
            font-size: 18px;
            font-family: middle;
            font-weight: 500;
            color: #38A3F1;
            white-space: nowrap;
        }
    }
}
</style>