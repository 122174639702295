<template>
    <div class="wrapper">
        <nav :ar="$i18n.locale === 'ar'">
            <h1 style="font-size: 24px; font-weight: bold; color: #000;">{{ title }}</h1>
            <div v-show="!hideMore" @click="$emit('more')">
                <span v-t="'viewAll'"></span>
                <img src="@/assets/imgs/home/形状 22.png" alt="">
            </div>
        </nav>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Category',
    props: {
        /**
         * 隐藏更多
         */
        hideMore: Boolean,
        /**
         * 标题
         */
        title: String,
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        &[ar] {
            direction: rtl;

            div {
                img {
                    transform: rotate(180deg);
                }
            }
        }

        h1 {
            margin: 0;
            font-size: 22px;
            font-weight: 500;
            color: #333333;
        }

        div {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 90px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            border-radius: 4px;
            font-size: 12px;
            color: #666666;
            justify-content: center;
            gap: 6px;
        }
    }
}
</style>