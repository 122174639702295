<template>
    <header>
        <main class="main" :ar="$i18n.locale === 'ar'">
            <div>
                <span v-t="'welcome'"></span>
                <span class="payment" v-t="'Happy'"></span>
            </div>
            <div class="right">
                <!-- 语言设置 -->
                <div class="language">
                    <a-icon type="global" />
                    <span v-t="$i18n.locale"></span>
                    <a-icon type="down" />
                    <div class="list" :ar="$i18n.locale === 'ar'">
                        <div @click="$i18n.locale = code" v-t="code" :active="$i18n.locale == code"
                            v-for="code in ['ar', 'zh', 'en']" :key="code"></div>
                    </div>
                </div>
               
                <!-- 货币 -->
                <div class="money">
                    <img :src="$store.state.currentCurrency?.url" alt="">
                    <span>{{ $store.state.currentCurrency?.tittle }}</span>
                    <a-icon type="down" />
                    <div class="list" :ar="$i18n.locale === 'ar'">
                        <div @click="currencyChange(item)"
                            :active="item.baseSetId === $store.state.currentCurrency?.baseSetId"
                            v-for="item in $store.state.currency" :key="item.baseSetId"><img :src="item.url" alt=""> {{
                                item.tittle }}</div>
                    </div>
                </div>
                 <!-- 手机东智 -->
                 <div class="phoneCode">
                  
                    <span v-t="'phoneCode'"></span>
                    <a-icon type="down" />
                    <div class="list">
                        <div class="imgbox">
                            <div class="imgList">
                                <div>
                                    <img src="@/assets/qcrCode/dongzhi.png" alt="" >
                                    <div>Google Play</div>
                                </div>
                                <div>
                                    <img src="@/assets/qcrCode/ioscode.png" alt="" >
                                   <div>Apple Store</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 铃铛 -->
                <div class="bell" @click="$router.push({ name: 'messages' })">
                    <img src="@/assets/imgs/home/bell_small.png" alt="">
                    <em v-if="$store.state.infoCounts?.hasNew"></em>
                </div>
                <!-- 如果已登录 -->
                <template v-if="$store.getters.token">
                    <!-- 用户信息 -->
                    <div class="userinfo" @click="$router.push({ name: 'me' })">
                        <img :src="$store.state.user.photoUrl" alt="">
                        <span>{{ $store.state.user.userName }}</span>
                    </div>
                    <!-- 分阁线 -->
                    <div class="split"></div>
                    <!-- 退出登录 -->
                    <span class="exit" v-t="'exit'" @click="exit"></span>
                </template>
                <template v-else>
                    <!-- 注册 -->
                    <router-link class="link" :to="{ name: 'register' }" v-t="'register'"></router-link>
                    <!-- 分阁线 -->
                    <div class="split"></div>
                    <!-- 登录 -->
                    <router-link class="link" :to="{ name: 'login' }" v-t="'login'"></router-link>
                </template>
            </div>
        </main>
    </header>
</template>

<script>
import { confirm } from '@/utils/common';

export default {
    name: 'Header',
    methods: {
        /**
         * 更改货币
         */
        currencyChange(currency) {
            this.$store.commit('setCurrentCurrency', currency);
            window.location.reload();
        },
        /**
         * 退出
         */
        async exit() {
            try {
                await confirm(this.$t('askExit'));
                this.$store.dispatch('exit');
            } catch (error) { }
        }
    },
    created() {
        this.$store.dispatch('getCurrency');

    }
}
</script>

<style lang="scss" scoped>
// 激活
@mixin active {
    font-weight: 500;
    color: #38A3F1;
}
.imgbox{
    position: relative;
    .imgList{
        border: 1px solid #EEEEEE;
        position: absolute;
        top: 0px;
        right: 0px;
       background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 9999;
       div{
       
        img{
            width: 90px;
           background-color: #fff;
        }
        div{
            padding: 4px 0;
        }
       }
    }
}
 .phoneCode:hover  .imgList{
    padding: 20px !important;
}
header {
    height: 34px;
    background: #424242;
    color: #FFFFFF;

    main {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &[ar] {
            direction: rtl;
        }

        .payment {
            padding: 0 58px;
        }

        .right {
            display: flex;
            align-items: center;

            // 语言相关
            .language {
                height: 34px;
                display: flex;
                align-items: center;
                padding: 0 13px 0 10px;
                cursor: pointer;
                position: relative;

                &:hover {
                    .list {
                        display: block;
                    }
                }

                .list {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100%;
                    background: #FBFBFB;
                    box-shadow: 2px 4px 8px 0px rgba(170, 170, 170, 0.22);
                    border-radius: 2px;
                    color: #333333;
                    font-weight: 400;
                    padding-bottom: 3px;

                    &[ar] div {
                        padding: 10px 15px 10px 0;
                    }

                    div {
                        padding: 10px 0;
                        padding-left: 30px;

                        &[active] {
                            @include active;
                        }

                        &:hover {
                            @include active;
                        }

                        &+div {
                            border-radius: 1px;
                            border-top: 1px solid rgba($color: #EEEEEE, $alpha: .5);
                        }
                    }
                }

                &:hover {
                    background: #686868;
                    color: #BDBDBD;
                }

                .anta-icon-global {
                    font-size: 15px;
                }

                span {
                    padding: 0 6px;
                }
            }
            // 语言相关
            .phoneCode {
                height: 34px;
                display: flex;
                align-items: center;
                padding: 0 13px 0 10px;
                cursor: pointer;
                position: relative;

                &:hover {
                    .list {
                        display: block;
                    }
                }

                .list {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100%;
                    background: #FBFBFB;
                    box-shadow: 2px 4px 8px 0px rgba(170, 170, 170, 0.22);
                    border-radius: 2px;
                    color: #333333;
                    font-weight: 400;
                    padding-bottom: 3px;

                    &[ar] div {
                        padding: 10px 15px 10px 0;
                    }

                    div {
                      
                        &[active] {
                            @include active;
                        }

                        &:hover {
                            @include active;
                        }

                        &+div {
                            border-radius: 1px;
                            border-top: 1px solid rgba($color: #EEEEEE, $alpha: .5);
                        }
                    }
                }

                &:hover {
                    background: #686868;
                    color: #BDBDBD;
                }

                .anta-icon-global {
                    font-size: 15px;
                }

                span {
                    padding: 0 6px;
                }
            }

            // 货币相关
            .money {
                margin: 0 10px;
                padding: 0 11px 0 9px;
                height: 34px;
                display: flex;
                align-items: center;
                padding: 0 13px;
                position: relative;
                cursor: pointer;

                &>img {
                    width: 14px;
                    height: 14px;
                }

                &:hover {
                    .list {
                        display: block;
                    }
                }

                .list {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100px;
                    background: #FBFBFB;
                    box-shadow: 2px 4px 8px 0px rgba(170, 170, 170, 0.22);
                    border-radius: 2px;
                    color: #333333;
                    font-weight: 400;
                    padding-bottom: 3px;
                    z-index: 99;
                    max-height: 300px;
                    overflow: auto;

                    img {
                        width: 20px;
                        height: 20px;
                    }

                    div {
                        padding: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &[active] {
                            @include active;
                        }

                        &:hover {
                            @include active;
                        }

                        &+div {
                            border-radius: 1px;
                            border-top: 1px solid rgba($color: #EEEEEE, $alpha: .5);
                        }
                    }
                }

                &:hover {
                    background: #686868;
                    color: #BDBDBD;
                }

                span {
                    padding: 0 7px;
                }
            }

            // 铃铛
            .bell {
                margin: 0 20px;
                cursor: pointer;
                position: relative;

                .anta-icon-bell {
                    font-size: 16px;
                }

                em {
                    display: inline-block;
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #E60013;
                    right: 3px;
                    top: 3px;
                }
            }

            // 用户信息
            .userinfo {
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    width: 22px;
                    height: 22px;
                    background: #949494;
                    border-radius: 50%;
                }

                span {
                    margin: 0 10px;
                }
            }

            // 分割线
            .split {
                width: 1px;
                height: 16px;
                background: #DFDFE4;
            }
        }

        // 退出登录
        .exit {
            cursor: pointer;
            margin: 0 10px;
        }

        // 链接
        .link {
            cursor: pointer;
            margin: 0 10px;
            color: white;
        }
    }
}
</style>