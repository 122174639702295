<template>
    <div>
        <Banner />
        <section class="main">
            <Slider />
            <SecondSlider />
            <List />
            <!-- <Recommand :list="home.recommendBrand" /> -->
        </section>
    </div>
</template>

<script>
import Banner from './Components/Banner.vue';
import Slider from './Components/Slider.vue';
import SecondSlider from './Components/SecondSlider.vue';
import List from './Components/List.vue';
import Recommand from './Components/Recommand.vue';
import { mapState } from 'vuex';

export default {
    name: 'Home',
    components: {
        List,
        Banner,
        Slider,
        Recommand,
        SecondSlider,
    },
    computed: {
        ...mapState(['home'])
    }
}
</script>

<style lang="scss" scoped>
section {
    padding-top: 30px;
    overflow: hidden;
}
</style>