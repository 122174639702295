<template>
    <a-select :value="value" @change="e => $emit('input', e)" :style="{ width }" v-bind="$attrs" v-on="$listeners">
        <a-select-option v-for="item in options" :key="item.value">
            {{ item.label }}
        </a-select-option>
    </a-select>
</template>

<script>
export default {
    name: "Select",
    inheritAttrs: false,
    props: {
        /**
         * 值
         */
        value: [Number, String],
        /**
         * 配置项
         */
        options: Array,
        /**
         * 宽度
         */
        width: String
    }
}
</script>

<style lang="scss" scoped>
.ant-select {
    width: 140px;
    border: 1px solid #D7D7D7;
    border-radius: 0;

    &.ant-select-lg {
        height: 36px;
        font-size: 14px;

        ::v-deep .ant-select-selection {
            height: 100%;
            border: 0;

            .ant-select-selection__rendered {
                height: 100%;

                .ant-select-selection-selected-value {
                    line-height: 36px;
                }
            }
        }
    }
}
</style>