<template>
    <div class="wish-goods-card-wrapper" :ar="$i18n.locale === 'ar'"
        @click="$router.push({ name: 'goodsDetail', params: { commodityId: goods?.commodityId } })">
        <div class="box">
            <Checkbox :value="isChecked" @change="$emit('change', goods.wishId)" />
            <img :src="splitImage(goods?.commodityUrls)" alt="">
            <div>
                <h1>{{ goods?.commodityName }}</h1>
                <div>
                    <Price :price="goods?.salsePrice" :markSize="20" :priceSize="24" />
                    <img @click.stop="collect" :src="!goods?.collectMark ? collected : unCollect" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/detail';

export default {
    name: 'WishGoodsCard',
    data() {
        return {
            /**
             * 已收藏图标
             */
            collected: require('@/assets/imgs/home/collect.png'),
            /**
             * 未收藏图标
             */
            unCollect: require('@/assets/imgs/home/uncollect.png')
        }
    },
    props: {
        /**
         * 是否选中
         */
        isChecked: Boolean,
        /**
         * 商品对象
         */
        goods: Object
    },
    methods: {
        /**
         * 收藏商品
         */
        async collect() {
            try {
                await api.commodityCollect({
                    commodityId: this.goods?.commodityId
                });
                this.goods.collectMark = !this.goods?.collectMark;
                this.$store.dispatch('getInfoCounts');
            } catch (error) { }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.wish-goods-card-wrapper {
    display: inline-block;
    width: 590px;
    height: 200px;
    background: #FFFFFF;
    border-radius: 4px;

    &[ar] {
        direction: rtl;
    }

    .box {
        display: flex;
        align-items: center;
        padding: 20px 25px 20px 20px;
        gap: 20px;

        &>img {
            width: 160px;
            height: 160px;
            background: #F9F9F9;
            border-radius: 10px;
        }

        &>div {
            height: 100%;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;

            h1 {
                font-size: 18px;
                color: #333333;
                line-height: 32px;
                font-weight: 400;
                height: 64px;
                @include line(2);
            }

            p {
                display: inline-block;
                height: 32px;
                line-height: 32px;
                padding: 0 9px;
                margin: 15px 0 8px;
                background: #FFFFFF;
                border: 1px solid #41A7F3;
                border-radius: 6px;
                color: #41A7F3;
            }

            div {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;

                img {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>