<template>
    <div @click="go" class="coupon" :ar="$i18n.locale === 'ar'">
        <p>{{ title }}</p>
        <main>
            <div class="left">
                <div class="discount">
                    <p class="name">{{ obj.businessType == 1 ? $t('newCoupon') : obj.shopName }}</p>
                    <Price :priceSize="60" :markSize="26" :price="obj.discount * 0.1" />
                </div>
                <div class="detail">
                    <p class="title" v-t="'xiadanzhekou'"></p>
                    <p class="content">{{ obj.discountDesc }}</p>
                    <p class="time">{{ dayjs(obj.startTime).format('YYYY.MM.DD') }}-{{
                        dayjs(obj.endTime).format('YYYY.MM.DD') }}</p>
                </div>
            </div>
            <div class="right" v-t="obj.isGet ? 'goUse' : 'immediatelyGet'" @click.stop="getDiscount"></div>
        </main>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import api from '@/api/table';

export default {
    name: 'BigCoupon',
    data() {
        return {
            dayjs
        }
    },
    props: {
        /**
        * 数据
        */
        obj: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        /**
         * 跳转
         */
        go() {
            if (this.$route.name === 'couponDetail') return;
            this.$store.commit('setCoupon', this.obj);
            this.$router.push({ name: 'couponDetail', params: { id: this.obj?.discountId } })
        },
        /**
         * 领取优惠券
         */
        async getDiscount() {
            try {
                if (this.obj.isGet) return this.go();
                await api.getDiscount({
                    discountId: this.obj.discountId
                });
            } catch (error) { }
        }
    },
    computed: {
        /**
         * 优惠券标题
         */
        title() {
            return this.obj?.platform ? this.$t('seller') : this.$t('platform');
        }
    }
}
</script>

<style lang="scss" scoped>
.coupon {
    width: 758px;
    height: 240px;
    font-size: 18px;
    display: inline-flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        display: inline-block;
        background: url(@/assets/imgs/home/bigcoupon.png);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &[ar] {
        direction: rtl;

        &::before {
            transform: rotateY(180deg);
        }

        main {
            padding: 18px 19px 18px 0;

            .left {
                .detail {
                    padding-right: 25px;
                }
            }
        }
    }

    &>p {
        width: 91px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        margin: 0;
        color: #694228;
        z-index: 10;
    }

    main {
        height: 140px;
        display: flex;
        align-items: center;
        padding: 18px 0 18px 19px;
        flex: 1;
        z-index: 10;

        .left {
            width: 611px;
            height: 100%;
            display: flex;

            .discount {
                width: 198px;
                text-align: center;

                .name {
                    margin: 34px 0 25px;
                    color: #825E43;
                }
            }

            .detail {
                padding-left: 25px;

                .title {
                    margin-top: 33px;
                    font-size: 22px;
                    color: #694228;
                }

                .content {
                    margin: 25px 0;
                    font-size: 20px;
                    color: #FC3E50;
                    font-family: normal;
                }

                .time {
                    font-size: 18px;
                    color: #976A3B;
                    font-family: normal;
                }
            }
        }

        .right {
            flex: 1;
            text-align: center;
            color: #FFFFFF;
            font-size: 22px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}
</style>