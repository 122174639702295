<template>
    <div class="captcha-wrapper">
        <Input :max="50" v-if="type == 0" v-model="mailTo" :readonly="readonly"
            :prefix="require('@/assets/imgs/users/email.png')" :ph="$t('emailAccountPh')" />
        <PhoneNumber :readonly="readonly" :hide="hide" :value="value" v-else ref="phone" :auto="auto" />
        <Input type="number" v-model="message" :max="6" :prefix="require('@/assets/imgs/users/captcha.png')"
            :ph="$t('capthcaPh')">
        <a @click="getCaptcha" :disabled="sending" href="javascript:;;">{{ countDown ?
            $t('secondResend').replace('{s}', countDown) : $t('getCaptcha') }}</a>
        </Input>
    </div>
</template>

<script>
import { message } from 'ant-design-vue';
import api from '@/api/user';

export default {
    name: 'Captcha',
    data() {
        return {
            /**
             * 倒计时
             */
            countDown: 0,
            /**
             * 是否发送中
             */
            sending: false,
            /**
             * 验证码
             */
            message: '',
            /**
             * 邮箱
             */
            mailTo: this.value || ''
        }
    },
    computed: {
        user() {
            return this.$store.state?.user;
        }
    },
    props: ['type', 'readonly', 'value', 'hide', 'auto'],
    created() {
        if (this.auto) {
            this.mailTo = this.user?.mailAddress;
        }
    },
    methods: {
        /**
         * 获取验证码
         */
        async getCaptcha() {
            try {
                if (this.type == 0 && !this.mailTo?.trim()) {
                    return message.warn(this.$t('required').replace('{t}', this.$t('emailAddress')));
                }
                if (this.type == 0 && !/^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/.test(this.mailTo)) {
                    return message.warn(this.$t('invalidEmail'));
                }
                let value = this.mailTo;
                const obj = {
                    registType: this.type,
                    [this.type == 0 ? 'mailTo' : 'tel']: value
                };
                if (this.type == 1) {
                    const phone = await this.$refs.phone.valid();
                    obj.tel = phone.tel;
                    obj.areaCode = phone.areaCode;
                }
                this.sending = true;
                await api.getCaptcha(obj);
                this.countDown = 60;
                const id = setInterval(() => {
                    if (--this.countDown === 0) {
                        this.sending = false;
                        clearInterval(id);
                    }
                }, 1000);
            } catch (error) {
                this.sending = false;
            }
        },
        /**
         * 验证数据
         */
        async valid() {
            if (!this.message.trim()) {
                message.warn(this.$t('required').replace('{t}', this.$t('captcha')));
                return Promise.reject();
            }
            if (this.type == 0) {
                if (!this.mailTo?.trim()) {
                    message.warn(this.$t('required').replace('{t}', this.$t('emailAddress')));
                    return Promise.reject();
                }
                if (!/^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/.test(this.mailTo)) {
                    message.warn(this.$t('invalidEmail'));
                    return Promise.reject();
                }
                return { mailAddress: this.mailTo, message: this.message };
            }
            const obj = await this.$refs.phone.valid();
            return { ...obj, message: this.message };
        }
    }
}
</script>

<style lang="scss" scoped>
.captcha-wrapper {
    margin: 25px 0;
}
</style>