<template>
    <div class="address-wrapper">
        <!-- 国家省市区 -->
        <div class="cpca">
            <a-select v-model="model.receiveUserAreaCode[0]" :options="countries"
                :placeholder="$t('choseCountryPh')"></a-select>
        </div>
        <a-input :maxLength="100" v-model="model.receiveUserDetailAddress" :placeholder="$t('detailAddressPh')"
            @change="change"></a-input>
    </div>
</template>

<script>
import api from '@/api/user';

/**
 * 地址选择器
 */
export default {
    name: 'Address',
    data() {
        return {
            /**
             * 数据
             */
            model: { receiveUserAreaCode: [], ...this.value },
            /**
             * 国家
             */
            countries: [],
            /**
             * 是否初始化
             */
            init: false
        }
    },
    watch: {
        value: {
            handler(v) {
                if (v !== null) {
                    this.model = { receiveUserAreaCode: [], ...v };
                    if (this.init || !this.model.receiveUserAreaCode.length) return;
                    this.init = true;
                }
            },
            immediate: true
        }
    },
    methods: {
        /**
         * 数据更改
         */
        change(target) {
            if (target !== 'countries') {
                const { receiveUserAreaCode, receiveUserDetailAddress } = this.model;
                if (receiveUserAreaCode?.length > 0 && receiveUserDetailAddress?.trim()) {
                    this.$emit('input', { ...this.model });
                    return this.$emit('change');
                }
                this.$emit('input', null);
                this.$emit('change');
            }
        },
        /**
         * 获取数据
         */
        async getData(pid, target, index) {
            try {
                this.model.receiveUserAreaCode.splice(index);
                const list = await api.areaList({ pid });
                this[target] = list.map(item => ({ label: item.name, value: item.id }));
                this.change(target);
            } catch (error) { }
        }
    },
    created() {
        this.getData(null, 'countries', 5);
    },
    props: ['value']
}
</script>

<style lang="scss" scoped>
.address-wrapper {
    width: 100%;

    .cpca {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding-bottom: 10px;

        ::v-deep {
            .ant-select-selection__placeholder {
                text-align: unset;
            }

            .ant-select-selection-selected-value {
                float: unset;
                padding: 0 22px;
            }
        }
    }
}
</style>