<template>
    <div :active="!!value" :ar="$i18n.locale === 'ar'" :en="$i18n.locale === 'en'"
        :class="['coupon', { chose, inactive: !!obj.isPass || !!obj.isUsed }]">
        <p>{{ title }}</p>
        <main>
            <div class="left">
                <div class="discount">
                    <p class="name cut">{{ obj.businessType == 1 ? $t('newCoupon') : obj.shopName }}</p>
                    <div class="price">
                        <template v-if="obj.cdiscountType == 1">
                            <Price :price="obj.discount + ''" :priceSize="27"
                                :color="(obj.isPass || obj.isUsed) ? '#999999' : undefined" />
                        </template>

                        <template v-else>
                            <div class="zhekou" v-html="$t('zhekou').replace('{v}', obj.discount.toFixed(1))"></div>
                        </template>
                    </div>
                </div>
                <div class="detail">
                    <p class="title" v-t="'xiadanzhekou'"></p>
                    <p class="content cut">{{ obj.discountDesc }}</p>
                    <p class="time" v-if="obj.startTime">{{ dayjs(obj[$i18n.locale == 'ar' ? 'endTime' :
        'startTime']).format('YYYY.MM.DD') }}-{{ dayjs(obj[$i18n.locale == 'ar' ? 'startTime' :
        'endTime']).format('YYYY.MM.DD') }}</p>
                </div>
            </div>
            <div @click="go" class="right"
                v-html="!chose ? (obj.isPass ? $t('expired') : obj.isGet ? $t('goUse') : obj.isUsed ? $t('used') : $t('immediatelyGetBr')) : ''">
            </div>
        </main>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import api from '@/api/table';

export default {
    name: 'Coupon',
    data() {
        return {
            dayjs
        }
    },
    methods: {
        /**
         * 跳转
         */
        async go() {
            // 如果是可选中状态
            if (this.chose) {
                this.$emit('input', !this.value);
                return this.$emit('change', this.obj.discountId);
            }
            if (this.$route.name === 'couponDetail' || this.obj.isPass || this.obj.isUsed) return;
            // 如果已获取则跳转
            if (this.obj.isGet) {
                this.$store.commit('setCoupon', this.obj);
                this.$router.push({ name: this.obj?.platform ? 'storeHome' : 'home', params: { id: this.obj?.shopId ?? this.obj?.discountId } })
            }
            else {
                // 如果是立即获取
                if (!this.$store.getters.token) return this.$router.push({ name: 'login', query: { redirect: 'coupon' } })
                try {
                    await api.getDiscount(this.obj.discountId);
                    this.$emit('change');
                } catch (error) { }
            }
        },
    },
    props: {
        /**
         * 是否被选中
         */
        value: Boolean,
        /**
         * 是否可选
         */
        chose: Boolean,
        /**
         * 数据
         */
        obj: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * 优惠券标题
         */
        title() {
            return this.obj?.platform ? this.$t('seller') : this.$t('platform');
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.coupon {
    width: 385px;
    height: 146px;
    font-size: 14px;
    display: inline-flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        display: inline-block;
        background: url(@/assets/imgs/home/coupon_active.png);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &[en] {
        main {
            .left {
                .price {
                    font-size: 20px !important;
                }
            }
        }
    }

    &[ar] {
        direction: rtl;

        &::before {
            transform: rotateY(180deg);
        }

        &.inactive::before {
            background: url(@/assets/imgs/home/coupon_inactive.png);
        }

        &.chose {
            &::before {
                background: url(@/assets/imgs/home/coupon_chose.png);
            }

            .right {
                text-align: right;
            }
        }

        main {
            padding: 10px 10px 10px 0;

            .left {
                .detail {
                    flex: 1;
                    padding: 0 15px 0 0;
                }

                .price {
                    .zhekou {
                        font-size: 15px;
                        white-space: nowrap;

                        :deep span {
                            font-size: 23px;
                        }
                    }
                }
            }

            .right {
                padding: 0 10px 0 0;
            }
        }
    }

    &.inactive {
        &::before {
            background: url(@/assets/imgs/home/coupon_inactive.png);
        }

        p {
            color: #999999 !important;
        }
    }

    &.chose {
        &::before {
            background: url(@/assets/imgs/home/coupon_chose.png);
        }

        &[active] {
            .right {
                &::before {
                    background: url(@/assets/imgs/home/coupon_right.png);
                }
            }
        }

        .right {
            text-align: left;

            &::before {
                content: '';
                display: inline-block;
                background: url(@/assets/imgs/home/radio_inactive.png);
                width: 24px;
                height: 24px;
            }
        }
    }

    &>p {
        width: 54px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        margin: 0;
        color: #694228;
        z-index: 10;
    }

    main {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 10px;
        padding-right: 0;
        z-index: 10;

        .left {
            width: 300px;
            height: 100%;
            display: flex;

            .discount {
                width: 106px;

                .name {
                    text-align: center;
                    padding: 24px 10px 0;
                    color: #825E43;
                    margin: 0;
                }

                .price {
                    direction: ltr;
                    padding: 15px 10px 0;
                    text-align: center;
                    font-size: 26px;
                    font-weight: bold;
                    font-family: bold;
                    color: #FC3E50;
                    margin: 0;
                    white-space: nowrap;
                }
            }

            .detail {
                flex: 1;
                padding-left: 15px;

                .title {
                    margin: 18px 0 0;
                    font-size: 18px;
                    color: #694228;
                }

                .content {
                    margin: 15px 0;
                    font-size: 16px;
                    font-family: normal;
                    color: #FC3E50;
                    width: 175px;
                }

                .time {
                    font-family: normal;
                    color: #976A3B;
                }
            }
        }

        .right {
            flex: 1;
            padding-left: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            position: relative;
            line-height: 26px;
            font-family: middle;
            cursor: pointer;
        }
    }
}
</style>