<template>
    <div class="filter-wrapper" :ar="$i18n.locale === 'ar'">
        <span v-t="'sort'"></span>
        <Sort v-model="value.salseSort" class="sales" :title="$t('salesVolume')" @change="$emit('ok')" />
        <Sort v-model="value.starLevle" :title="$t('credit')" @change="$emit('ok')" />
        <span class=" filter" v-t="'filter'"></span>
        <label v-t="'storeType'"></label>
        <Select v-model="value.shopTypeId" width="200px" :options="storeTypes" size="large"
            :placeholder="$t('storeTypePh')" />
        <label class="area" v-t="'choseArea'"></label>
        <TS style="width:180px" :tree-data="areas" :placeholder="$t('choseAreaPh')" :loadData="loadData"
            v-model="value.shopAreaCode" />
        <Button size="default" class="button-ok" middle shape="round" v-t="'ok'" @click="$emit('ok')"></Button>
        <Button size="default" middle shape="round" type="default" v-t="'reset'"
            @click="$emit('input', { ...value, shopAreaCode: '', shopTypeId: '', pageNumber: 1 }); $emit('ok')"></Button>
        <label @click="$store.commit('setShowMark', !$store.state.showMark); $emit('ok');">
            <img src="@/assets/imgs/home/change.png" alt="">
            {{ $t('changeStyle') }}
        </label>
    </div>
</template>

<script>
import api from '@/api/home';
import user from '@/api/user';

/**
 * 门店过滤
 */
export default {
    name: 'StoreFilter',
    data() {
        return {
            /**
             * 店铺类型
             */
            storeTypes: [],
            /**
             * 地区列表
             */
            areas: [{ id: '', value: '', title: this.$t('allA') }]
        }
    },
    methods: {
        /**
         * 值改变
         */
        change(obj) {
            this.$emit('input', obj);
            this.$emit('change', obj);
        },
        /**
         * 获取店铺类型
         */
        async getShopTypes() {
            try {
                const list = await api.getShopType();
                this.storeTypes = [{ label: this.$t('allA'), value: '' }, ...list.map(c => ({ label: c.shopTypeName, value: c.shopTypeId }))];
            } catch (error) { }
        },
        /**
         * 
         * @param {*} node 
         */
        loadData(node) {
            return new Promise(async resolve => {
                const { id } = node.dataRef;
                if (!id) return resolve();
                await this.getAreas(id);
                resolve();
            });
        },
        /**
         * 获取地区列表
         */
        async getAreas(pid) {
            try {
                let list = await user.areaList({ pid });
                list = list.map(({ id, name, pid }) => ({ id, value: id, title: name, pId: pid }));
                this.areas.push(...list);
            } catch (error) { }
        }
    },
    props: {
        /**
         * 绑定的数据
         */
        value: Object
    },
    created() {
        this.getShopTypes();
        this.getAreas();
    }
}
</script>

<style lang="scss" scoped>
.filter-wrapper {
    display: flex;
    align-items: center;
    height: 60px;
    background: #FFFFFF;
    padding-left: 18px;
    font-size: 14px;
    color: #999999;

    &[ar] {
        direction: rtl;
        padding: 0 18px 0 0;

        label {
            color: #555555;
            padding-left: 12px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.area {
                padding-right: 20px;
            }

            img {
                padding: 0 20px 0 10px;
            }
        }

        .button-ok {
            margin: 0 20px 0 10px;
        }
    }

    .sales {
        padding: 0 15px;
    }

    .filter {
        padding: 0 20px;
    }

    label {
        color: #555555;
        padding-right: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.area {
            padding-left: 20px;
        }

        img {
            padding: 0 10px 0 20px;
        }
    }

    .button-ok {
        margin: 0 10px 0 20px;
    }
}
</style>