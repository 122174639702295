<template>
    <div class="image-uploder-wrapper" :style="{ width: width + 'px' }">
        <div class="img" v-for="(item, index) in pics" :key="index">
            <img src="@/assets/imgs/home/close_red.png" @click="deletePic(index)" alt="">
            <img :src="item" alt="">
        </div>
        <label class="plus">
            <input :multiple="multi" type="file" @change="change" accept="image/png,image/jpeg,image/gif,image/bmp">
            <img src="@/assets/imgs/home/plus_big.png" alt="">
        </label>
    </div>
</template>

<script>
/**
 * 获取对象URL
 */
const getObjectURL = file => (window.URL || window.webkitURL || window).createObjectURL(file);
export default {
    name: 'ImageUploder',
    data() {
        return {
            /**
             * 图片列表
             */
            pics: []
        }
    },
    methods: {
        /**
         * 删除图片
         * @param {*} index 下标
         */
        deletePic(index) {
            this.pics.splice(index, 1);
            if (!this.value?.length) return;
            const value = [...this.value];
            value.splice(index, 1);
            this.$emit('input', value);
        },
        /**
         * 选择图片
         */
        change(e) {
            const files = e.target.files;
            if (!files.length) return;
            const imgs = [];    //图片列表
            for (const file of files) {
                const { type, size } = file;
                if (!/^image\//.test(type)) {
                    continue;
                }
                if (size > this.size) {
                    continue;
                }
                const url = getObjectURL(file);
                imgs.push(file);
                this.pics.push(url);
            }
            (this.value instanceof Array) && imgs.unshift(...this.value);
            this.$emit('input', [...imgs]);
        }
    },
    props: {
        /**
         * 宽度
         */
        width: Number,
        /**
         * 文件大小
         */
        size: Number,
        /**
         * 是否支持多选
         */
        multi: Boolean,
        /**
         * 图片列表
         */
        value: Array
    }
}
</script>

<style lang="scss" scoped>
.image-uploder-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 20px;

    div,
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background: #F9F9F9;
        border-radius: 4px;
        position: relative;

        &.plus {
            input {
                display: none;
            }
        }

        &.img {
            background: #E0DEE1;

            img {
                &:first-child {
                    position: absolute;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                }

                &:last-child {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>