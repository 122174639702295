<template>
    <div class="filter-wrapper" :ar="$i18n.locale === 'ar'">
        <div class="box">
            <span v-t="'sort'"></span>
            <Sort class="price" v-model="value.amountSort" @change="$emit('ok')" :title="$t('price')" />
            <Sort :title="$t('salesVolume')" v-model="value.salseSort" @change="$emit('ok')" />
            <span class="filter" v-t="'filter'"></span>
            <label v-t="'priceRange'"></label>
            <div class="input">
                <NumberInput size="large" :value="value.minAmount"
                    @change="$emit('input', { ...value, minAmount: $event })" />
                <em></em>
                <NumberInput size="large" :value="value.maxAmount"
                    @change="$emit('input', { ...value, maxAmount: $event })" />
            </div>
            <label class="chose-brand" v-t="'choseBrand'"></label>
            <Select :options="brandList" size="large" v-model="value.brandId" :placeholder="$t('choseBrandPh')" />
            <Button size="default" class="button-ok" middle shape="round" v-t="'ok'" @click="$emit('ok')"></Button>
            <Button size="default" middle shape="round" type="default" v-t="'reset'"
                @click="$emit('input', { ...value, minAmount: '', maxAmount: '', brandId: '' }); $emit('ok');"></Button>
        </div>
    </div>
</template>

<script>
import api from '@/api/table';

export default {
    name: 'GoodsFilter',
    data() {
        return {
            /**
             * 列表
             */
            list: [],
        }
    },
    computed: {
        /**
         * 品牌列表
         */
        brandList() {
            return [{ label: this.$t('allA'), value: '' }, ...this.list.map(c => ({
                label: c.brandName,
                value: c.brandId
            }))];
        }
    },
    methods: {
        /**
         * 获取列表
         */
        async getList() {
            try {
                const { data } = await api.brandList({ pageNumber: 1, pageSize: 99999999, shopId: this.value.shopId });
                this.list = data;
            } catch (error) { }
        }
    },
    created() {
        this.getList();
    },
    props: {
        value: Object
    }
}
</script>

<style lang="scss" scoped>
.filter-wrapper {
    background: #FFFFFF;
    padding-left: 15px;

    &[ar] {
        direction: rtl;
        padding: 0 15px 0 0;
    }

    .box {
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 14px;
        color: #999999;
        justify-content: space-between;
        max-width: 1000px;

        .input {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        label {
            color: #555555;
        }

        em {
            display: inline-block;
            width: 14px;
            height: 2px;
            background: #FFFFFF;
            border: 1px solid #D7D7D7;
            border-radius: 1px;
        }
    }
}
</style>