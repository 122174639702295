<template>
    <div class="password">
        <input type="number" @input="inputPassword">
        <span v-for="index in 6" :key="index">{{ value?.length >= index ? '●' : '' }}</span>
    </div>
</template>

<script>
export default {
    name: 'PasswordInput',
    props: {
        /**
         * 密码
         */
        value: {
            type: [Number, String],
            default: () => ''
        }
    },
    methods: {
        /**
         * 输入密码
         */
        inputPassword({ target }) {
            let { value } = target;
            if (value.length > 6) {
                target.value = value = value.slice(0, 6);
            }
            this.$emit('input', value);
        },
    }
}
</script>

<style lang="scss" scoped>
.password {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    input {
        display: block;
        position: absolute;
        left: 0;
        top: 20px;
        right: 0;
        bottom: 20px;
        opacity: 0;
    }

    span {
        outline: none;
        border: 1px solid #EAEAEA;
        height: 77px;
        width: 77px;
        font-size: 20px;
        text-shadow: 0 0 0 black;
        display: flex;
        align-items: center;
        justify-content: center;

        &+input {
            border-left: none;
        }
    }
}
</style>