<template>
    <a-checkbox v-on="$listeners" @click.stop :checked="!!value"
        @change="e => { $emit('input', e.target.checked); $emit('changeValue', e.target.checked) }">
        <slot></slot>
    </a-checkbox>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        /**
         * 是否选中
         */
        value: Boolean,
    }
}
</script>

<style lang="scss" scoped>
.ant-checkbox-wrapper {
    ::v-deep .ant-checkbox {
        width: 20px;
        height: 20px;

        &+span {
            font-size: 16px;
            font-weight: 500;
            color: #666666;
        }

        .ant-checkbox-inner {
            width: 20px;
            height: 20px;

            &::after {
                width: 7px;
                height: 12px;
                margin-top: -1px;
            }
        }
    }
}
</style>