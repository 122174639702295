<template>
    <textarea :value="value" @input="e => $emit('input', e.target.value)"
        :style="{ width: width + 'px', height: height + 'px' }"></textarea>
</template>

<script>
export default {
    name: 'TextArea',
    props: {
        /**
         * 值
         */
        value: String,
        /**
         * 宽度
         */
        width: Number,
        /**
         * 高度
         */
        height: Number
    }
}
</script>

<style lang="scss" scoped>
textarea {
    width: 100%;
    background: #FCFCFC;
    border-radius: 10px;
    resize: none;
    border: none;
    outline: none;
    padding: 25px;
    color: #999999;

    &::placeholder {
        color: currentColor;
    }
}
</style>