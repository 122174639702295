/**
 * 公共方法文件
 */

import { Modal } from 'ant-design-vue';
import { languageKey, messages } from '@/i18n';

/**
 * 语言
 */
const t = code => {
    const language = window.localStorage.getItem(languageKey) || 'zh';
    return messages[language][code];
}

/**
 * 询问
 */
export const confirm = (content, title, options) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            content,
            title: title || t('ask'),
            cancelText: t('cancel'),
            okText: t('ok'),
            onOk(close) {
                close();
                resolve();
            },
            onCancel(close) {
                typeof close === 'function' && close();
                reject();
            },
            ...options
        })
    });
}

/**
 * 询问
 */
export const asyncConfirm = (content, title, options) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            content,
            title: title || t('ask'),
            cancelText: t('cancel'),
            okText: t('ok'),
            onOk() {
                return new Promise(res => {
                    resolve(res);
                });
            },
            onCancel(close) {
                typeof close === 'function' && close();
                reject();
            },
            ...options
        })
    });
}

/**
* 值和状态的对应
*/
export const statusValue = {
    'tradeCancel': 0,
    'unPay': 1,
    'unSend': 2,
    'unReceive': 3,
    'unAssem': 4,
    'tradeDone': 5,
    /**
     * 售后中 8:售后未通过
     */
    'maintaining': [6, 8],
    'maintainUnsend': 7,
    /**
     * 10:申诉失败 订单完成 钱给商家  -> 状态变成：交易完成
     * 11:申诉通过 钱给用户，订单取消 -> 状态变成：交易取消
     */
    'appealling': [9, 10],
    'appealPassed': 11,
    'sendUnConfirm': 12,
    /**
     * 可以撤回 变成之前状态
     */
    'repaybacking': 13
}

/**
 * 值和状态的对应
 */
export const valueStatus = Object.keys(statusValue).reduce((obj, key) => {
    const value = statusValue[key];
    if (Array.isArray(value)) {
        value.forEach(item => {
            obj[item] = key;
        });
    } else {
        obj[value] = key;
    }
    return obj;
}, {})

/**
 * 其他值和状态的对应
 */
export const otherStatusValue = {
    'repaybacking': 1,
    'maintainPassed': 2,
    'maintainRejected': 3,
    'maintainUnsend': 4,
    'sendUnConfirm': 5,
    'appealling': 7,
    'appealPassed': 8,
}

/**
 * 其他值和状态的对应
 */
export const otherValueStatus = Object.keys(otherStatusValue).reduce((obj, key) => {
    const value = otherStatusValue[key];
    if (Array.isArray(value)) {
        value.forEach(item => {
            obj[item] = key;
        });
    } else {
        obj[value] = key;
    }
    return obj;
}, {})