<template>
    <label :style="{ width: size + 'px', height: size + 'px' }">
        <input :checked="value" @change="e => {
                $emit('input', e.target.checked);
                $emit('change', e.target.checked);
            }" type="radio" :name="name">
        <em></em>
    </label>
</template>

<script>
export default {
    name: 'Radio',
    props: {
        /**
         * 大小
         */
        size: {
            type: Number,
            default: 16
        },
        /**
         * 值
         */
        value: Boolean,
        /**
         * name
         */
        name: {
            type: String,
            default: 'default'
        }
    }
}
</script>

<style lang="scss" scoped>
label {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    input {
        opacity: 0;

        &:checked+em {
            border: 5px solid #DA251E;
        }
    }

    em {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 2px solid #D7D6D6;
        border-radius: 50%;
    }
}
</style>