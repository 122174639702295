<template>
    <div class="navigator" :ar="$i18n.locale === 'ar'">
        <a @click="$router.back(-1)">
            <img src="@/assets/imgs/home/形状 692.png" alt="">
            <span v-t="'backToLastPage'"></span>
        </a>
        <h1 v-if="title">{{ title }}</h1>
    </div>
</template>

<script>
export default {
    name: 'Navigator',
    props: {
        /**
         * 标题
         */
        title: String
    }
}
</script>

<style lang="scss" scoped>
.navigator {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;

    &[ar] {
        direction: rtl;

        img {
            transform: rotate(180deg);
        }
    }

    a {
        display: flex;
        align-items: center;

        span {
            padding: 0 9px;
            font-weight: 500;
            color: #666666;
        }
    }

    h1 {
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>