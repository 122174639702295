<template>
    <label>
        <input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)">
        <em></em>
    </label>
</template>

<script>
/**
 * 圆形多选框
 */
export default {
    name: 'RoundCheckbox',
    props: ['value']
}
</script>

<style lang="scss" scoped>
label {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    vertical-align: middle;

    input {
        opacity: 0;
        position: absolute;

        &:checked+em {
            border-width: 0;
            background: url(@/assets/imgs/home/round_check.png);
        }
    }

    em {
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #D5D5D5;
    }
}
</style>