<template>
    <div class="other-login-type-wrapper" :style="{ width: typeof width === 'string' ? width : width + 'px' }">
        <a href="javascript:void(0)" @click="facebook"><img src="@/assets/imgs/users/facebook.png" alt=""></a>
        <a
            :href="`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=aDB3elFicnc4QklMeW5obzVTbGI6MTpjaQ&redirect_uri=${host}&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`"><img
                src="@/assets/imgs/users/x.png" alt=""></a>
        <!-- <a href="http://www.facebook.com" target="_blank"><img src="@/assets/imgs/users/imessage.png" alt=""></a> -->
        <a
            :href="`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&state=3EAB37D9D5310BFE&redirect_uri=${host}&client_id=1034432601688-g845dmhv50mvpkiams8nb8easmpqhjhf.apps.googleusercontent.com`"><img
                src="@/assets/imgs/users/google.png" alt=""></a>
    </div>
</template>

<script>
// import '@/utils/connect.facebook.net_en_US_sdk';
import api from '@/api/user';

const { origin } = window.location;

export default {
    name: 'OtherLoginType',
    data() {
        return {
            init: false,
            host: origin + '/login'
        }
    },
    methods: {
        /**
         * 设置用户信息
         */
        setUser(user) {
            this.$store.commit('setUser', user);
            this.$router.replace({ name: 'home' });
        },
        /**
         * facebook登录
         */
        async facebookLogin(userId) {
            try {
                const user = await api.facebook(userId);
                this.setUser(user);
            } catch (error) { }
        },
        /**
         * facebook登录
         */
        facebook() {
            const { FB } = window;
            if (!this.init) {
                FB.init({
                    appId: '674620081409362',
                    cookie: false,
                    xfbml: true,
                    version: 'v17.0'
                });
                this.init = true;
            }
            FB?.login(response => {
                const { authResponse, status } = response;
                if (status === 'connected') {
                    console.log(authResponse);
                    const { userID } = authResponse;
                    console.log(userID);
                    this.facebookLogin(userID);
                }
            });
        },
        /**
         * google登录
         */
        async googleLogin(code) {
            try {
                const user = await api.googleLogin(code);
                this.setUser(user);
            } catch (error) { }
        },
        /**
         * twttier登录
         */
        async twttierLogin(code) {
            try {
                const user = await api.twitterLogin(code);
                this.setUser(user);
            } catch (error) { }
        }
    },
    created() {
        const { fullPath } = this.$route;
        if (!fullPath) return;
        const params = new URLSearchParams(fullPath);
        const googleCode = params.get('access_token');
        if (googleCode) {
            return this.googleLogin(googleCode);
        }
        const twttierCode = params.get('code');
        if (twttierCode) {
            this.twttierLogin(twttierCode);
        }
    },
    props: {
        width: {
            type: [String, Number],
            default: 260
        }
    }
}
</script>

<style lang="scss" scoped>
.other-login-type-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    img {
        width: 36px;
        height: 36px;
    }
}
</style>