export default {
  newShare: "Share & Earn",
  title: "ZAYYA",
  welcome: "Hi, welcome to ZAYYA Shopping Platform !",
  welcomeShort: "Welcome to ZAYYA !",
  Happy:"Happy Life ^_^",
  payment: "Pay on delivery!",
  zh: "中文",
  en: "English",
  phoneCode: "APP",
  download: "download",
  ar: "العربية",
  zhMoney: "CNY",
  moneyMark: "¥",
  exit: "Log out",
  goods: "Product",
  pleaselogin:"Please log in first",
  search: "Search",
  searchPh: "Please insert keywords to search",
  wish: "Sharing",
  collect: "Favorites",
  cart: "Cart",
  home: "Home",
  allBrand: "All Brands",
  allStore: "All Shops",
  coupon: "Coupons",
  couponA: "Coupon:",
  partyEntry: "Activities",
  sellerCenter: "Sellers Hub",
  concat: "Contact Us",
  about: "About US",
  open: "Open a Shop",
  scoreRule: "Reward Points System",
  qa: "FAQ",
  map: "Website Map",
  paymentOptions: "Payment Methods",
  paypall: "PayPal",
  stripe: "Stripe",
  receivePay: "Pay on delivery",
  fovar: "Follow Us",
  facebook: "Facebook",
  tweeter: "X",
  youtube: "Youtube",
  instaram: "Instagram",
  private: "Privacy Policy",
  orderTerm: "Billing Terms and Conditions",
  copyright: "Copyright ©2023 ZAYYA",
  beian: "沪公网安备 3101000000002948号 | 沪ICP备15000000号-1",
  allCategories: "All Categories",
  viewAll: "View All",
  backToLastPage: "Back to Previous Page",
  messages: "Message List",
  myCoupon: "My Coupons",
  all: "All",
  used: "Used",
  unused: "Unused",
  expired: "Expired",
  seller: "Seller",
  platform: "Platform",
  discount: "Discount",
  goUse: "Use",
  myCollection: "My Favorites",
  salesTitle: "Order Details",
  checkAll: "Select All",
  removeCollection: "Remove from favorites",
  total: "Total {total}",
  myOrder: "My Orders",
  key: "Keywords",
  keyPh: "Product Title Keywords",
  orderType: "Order Type",
  orderTypePh: "Please select order type",
  orderStatus: "Order Status",
  orderStatusPh: "Please select order status",
  orderTime: "Order Time",
  startTime: "Start Time",
  endTime: "End Time",
  to: "to",
  allOrder: "All Orders",
  unPay: "Pending Payment",
  unSend: "To be shipped",
  unReceive: "To be delivered",
  unAssem: "To be evaluated",
  maintaining: "After Sales",
  baby: "Product",
  price: "Price",
  count: "Qty.",
  paiedMoney: "Due Amount",
  action: "Action",
  orderTimeA: "Order Time:",
  orderNo: "Order No.: ",
  containPostFee: "Including Shipping Fees: ",
  orderDetail: "Order Details",
  pay: "Pay",
  cancel: "Cancel",
  applyReback: "Request a Refund",
  confirmReceive: "Confirm Delivery",
  viewTansform: "Track your Order",
  applyMaintain: "After Sales",
  goAssem: "Evaluate",
  recall: "Cancel After Sales",
  platformAppeal: "Claim",
  viewAssem: "Check Reviews",
  confirm: "Submit",
  confirmReceived: "Confirm Delivery",
  close: "Close",
  ok: "Confirm",
  transformCompany: "Delivery Company",
  transformNo: "Tracking No.",
  confirmReceiveGoods: "Did you receive the order and confirm it?",
  myWishList: "My Sharing",
  removeWishList: "Remove from list",
  copyLink: "Copy Link",
  orderRecords: "Orders Record",
  myAddress: "My Addresses",
  receiver: "Recipient",
  receiverPh: "The recipient's name",
  concatPhone: "Contact Number",
  concatPhonePh: "A contact number",
  receiveAddress: "Recipient Address",
  receiveAddressPh: "Country - Province - City - District",
  detailAddress: "Full Address",
  detailAddressPh: "Please insert full address (including House Number)",
  postCode: "Postal Code",
  postCodePh: "Postal code",
  required: "{t} Cannot be blank",
  minMax: "{t} length {min} to {max}",
  min: "{t} minimum Length {min}",
  max: "{t} maximum Length {max}",
  addAddress: "Add new address",
  save: "Save",
  myAddresses: "My Delivery Addresses",
  belongsToAddress: "District",
  mobileOrPhone: "Mobile phone number/email",
  defaultAddress: "Default Address",
  setDefaultAddress: "Set as default address",
  delete: "Delete",
  orderUser: "Client:",
  orderGoods: "Product: ",
  xiadanDate: "Date: ",
  score: "Reward Points ",
  moneyCoupon: "RMB Coupons",
  viewMore: "See more",
  partyTime: "Activity Time: ",
  newCoupon: "New User Coupons",
  newEnjoy: "Exclusively for newcomers, enjoy discounts when placing an order",
  immediatelyGet: "Claim Reward",
  sort: "Sort",
  salesVolume: "Sales Volume",
  filter: "Filter",
  priceRange: "Price Range",
  choseBrand: "Brand Selection",
  choseBrandPh: "Brand",
  reset: "Reset",
  credit: "Credibility",
  storeType: "Shop Type",
  storeTypePh: "Please select the shop type",
  choseArea: "District Selection ",
  choseAreaPh: "District",
  changeStyle: "Switch Style",
  fans: "Fans:",
  selfSell: "Direct-to-customer",
  enterStore: "Enter the shop",
  money: "Amount",
  storeName: "Store Name:",
  moveToCollection: "Move to favorites",
  addToWishlish: "Add to my sharing",
  payMoney: "Checkout",
  amount: "Total (excl. shipping fees):",
  checked: "Selected",
  jian: "Piece (pc) / Pieces (pcs)",
  iWantleaveMessage: "Write Message",
  inputMessage: "Please enter a message …",
  confirmMessage: "Send message",
  searchResult: "Search Results",
  appeal: "Claims",
  confirmShouhou: "Submit",
  appealDesc: "Claim Details",
  uploadImage: "Upload Image",
  appealDescPh: "Please enter claim details",
  shouhouType: "After-Sale Services Types",
  shouhouTypePh: "Please choose after sales type",
  shouhouGoods: "Sold Product",
  repayMoney: "Refund Amount",
  repayMoneyPh: "Please enter the amount to be refunded",
  repayReason: "Refund Reason",
  repayReasonPh: "Please enter the reason for refund",
  transformInfo: "Shipping Information",
  transformStatus: "Delivery Status",
  transformSendInfo: "Delivery Information",
  choseReceiveAddress: "Select Delivery Address",
  receiveInfo: "Reception Information",
  modifyAddress: "Modify Address",
  confirmOrderInfo: "Confirm Order Information",
  storeNameHeader: "Store Name",
  xiaoji: "Subtotal",
  amountContainPostfee: "Total (incl. shipping fees:${m})",
  paiedMoneyColon: "Due Amount:",
  confirmOrder: "Submit Order",
  remark: "Notes:",
  postType: "Shipping Method:",
  post: "Express",
  chosePayment: "Select Payment Method",
  gotoPay: "Confirm",
  finishOrder: "You have placed you order, please wait patiently for delivery!",
  back: "Back",
  viewOrder: "View Order",
  cashOnDeliveryTip:
    "Only registered members can check the order, confirm the receipt of the goods, request returns and exchanges and enjoy offers & discounts",
  continuePay: "Proceed to checkout",
  registerVIP: "Signup",
  currentBrand: "Current Brand:",
  toBeSeller: "Become a Seller",
  sellerName: "Merchant Name",
  sellerNamePh: "Please insert the merchant name",
  invalidPhone: "Mobile number format is not correct",
  emailAddress: "Email Address",
  emailAddressPh: "Please insert the email address",
  invalidEmail: "Email address is not correct",
  storeAddress: "Store Address",
  choseCountryPh: "Country",
  choseProvincePh: "Province",
  choseCityPh: "City",
  manageClass: "Business Category",
  brandAuth: "Brand Authorization Documents",
  clickUpload: "Upload",
  single: "(Single Selection)",
  manageLicense:
    "Business License and Industry Related Qualification Certificates",
  exclusiveShop: "Specialty Store",
  flagshipStore: "Flagship Store",
  typeShow: "Display by type",
  joinDesc: "Signup means agreeing to the terms of this platform",
  joinAgreeOn: '"Registration Agreement"',
  auditStatus: "Approval Status:",
  auditing: "Processing",
  auditSuccess: "Approved",
  failReason: "Reasons for rejection:",
  auditSuccessResult:
    "Merchant Account and password notification has been sent via email/SMS, please check it. If you have any questions, please contact our customer support.",
  reSubmit: "Re-submit",
  qa: "FAQ",
  privateTerm: "Privacy Policy",
  scoreRule: "Reward Points System",
  billTerm: "Billing Terms and Conditions",
  id: "ID:{v}",
  editInfo: "Edit Information",
  collectGoods: "Favorite Products",
  collectStore: "Favorite Stores",
  username: "Username",
  usernamePh: "Please enter the username",
  phone: "Mobile No.",
  unbind: "Unbound",
  emailAccount: "Email Account",
  emailAccountPh: "Please enter the email account",
  password: "Password",
  passwordPh: "Please enter the password",
  inputPasswordAgain: "Please re-enter the password",
  confirmPassword: "Confirm password",
  bindPhone: "Bind mobile number",
  changeEmail: "Change email",
  concatSeller: "Contact",
  focusOnStore: "Follow",
  immediatelyGetBr: "Now<br />Collect",
  couponGoods: "The following products participate in the activity:",
  hadAccount: "Already have an account?",
  gotoLogin: "Sign in",
  noAccount: "Don't have an account yet?",
  gotoRegister: "Signup now",
  forgotPassword: "Forget the password",
  register: "Signup",
  accountPh: "Username / Mobile No. / Email",
  login: "Sign in",
  otherLoginType: "Other login methods",
  emailRegister: "Email Registration",
  phoneRegister: "Mobile No. Registration",
  capthcaPh: "Please enter verification code",
  getCaptcha: "Get verification code",
  otherRegisterType: "Other login methods",
  bindEmail: "Bind email",
  bindPhone: "Bind mobile number",
  phonePh: "Please enter mobile number",
  recommendGoods: "Recommended Products",
  allGoods: "All Products",
  store: "Stores",
  brand: "Brands",
  quotationMark: '"{v}"',
  passwordNotSame: "The passwords do not match",
  captcha: "Verification code",
  invalidEmail: "Invalid email format",
  secondResend: "will be sent after {s} seconds",
  invalidPhone: "Invalid phone number",
  askExit: "Are you sure to quit?",
  bind: "Bind",
  askDelete: "Are you sure you want to delete?",
  emailFind: "Email Recovery",
  phoneFind: "Mobile Number Recovery",
  changePhone: "Change mobile number",
  oldEmailAccount: "Old Email Account",
  newEmailAccount: "New Email Account",
  oldPhone: "Old Mobile Number",
  newPhone: "New Mobile Number",
  oldPassword: "Old Password",
  newPassword: "New Password",
  joinAgreement: "Registration Agreement",
  hotRecommend: "Top Recommended",
  zhekou: "{v} %off",
  xiadanzhekou: "Get a discount",
  cancelFocus: "Unfollow",
  allA: "All",
  unreadAgreement:
    'Please read the "Registration Agreement" and click on agree',
  shopPic: "Store Photos",
  rejected: "Declined",
  passed: "Approved",
  goodsDetail: "Product Details",
  goodsComment: "Product Reviews",
  originPrice: "Original Price: ",
  priceA: "Price:",
  collectA: "Save in favorites",
  salesed: "Sold:{v}",
  guessYouLike: "You may also like",
  commentCount: "{v}. reviews",
  activeEndTime: "Activity End Time:",
  sendTime: "Delivery Time:{v}",
  sellerA: "Seller:{v}",
  viewShop: "View store",
  addToCart: "Add to the cart",
  immediatelyBuy: "Buy now",
  topSalesGoods: "Best-selling",
  rateScore: "Score:",
  giveScore: "Points:{v}",
  shou: "Receive",
  choseReceiveAddressA: "Please select delivery address",
  shouhoubaozhang: "After-sales support",
  recommendBrand: "Brand Recommendation",
  tradeDone: "Transaction completed",
  askRepayback: "Are you sure you want to cancel the order and get a refund?",
  confirmReceiveA: "Did you receive the order and confirm it?",
  changeAvatar: "Change profile photo",
  countLessOne: "The quantity cannot be less than 1",
  noSpec: "Please select specifications",
  msgEmpty: "Note content can not be empty",
  choseCoupon: "Please select a coupon",
  maintainRejected: "After-sales denied",
  tradeCancel: "Cancel transaction",
  askReturn: "Are you sure you want to withdraw?",
  askCancel: "Are you sure you want to cancel?",
  concater: "Contact:",
  concatPhoneA: "Contact phone No.:",
  receiveAddressA: "Delivery Address:",
  transformCom: "Delivery Company:",
  transformNoA: "Tracking No.:",
  payTime: "Payment Time:",
  paymentType: "Payment Methods:",
  orderInfo: "Order Information",
  sendTimeA: "Delivery Time:",
  maintainTime: "After-sales Time",
  maintainDesc: "After-sales Description",
  returnType: "Refund Type:",
  returnMoney: "Refunded Amount:",
  sellerRecall: "Merchant reply:",
  appealDesc: "Claim description",
  viewTransformInfo: "View shipping information",
  onlyReturnMoney: "Refund Only",
  returnMoneyAndGoods: "Return & Refund",
  singlePrice: "Unit Price",
  totalPrice: "Total Price:",
  postFee: "Shipping Fees:",
  returnPoint: "Refundable points:{v} points",
  maintainPassed: "After-sales Successful",
  maintainUnsend: "After-sale approved, to send back the product",
  appealling: "Processing Claim",
  appealUnpass: "Claim denied",
  appealPassed: "Claim accepted",
  sendUnConfirm: "Product return to be confirmed",
  buyGoods: "Send product order",
  sellerSendGoods: "Seller sent the order",
  commentOrder: "Evaluate the Order",
  orderDone: "Order completed",
  editInfo: "Modify information",
  leftPayTime: "Payment time remaining:{v}",
  cancelOrder: "Cancel the order",
  repayBack: "Refund",
  descMatch: "The description matches:",
  commentPh: "Maximum 300 characters",
  image: "Photo:",
  commentContentEmpty: "Comment content cannot be empty",
  singlePriceA: "Unit Price",
  leftReceiveTime:
    "There are {v} days left in your order and it will be automatically confirmed. If you have not received the item, please contact the seller in time!",
  maintainNoGoods:
    "Please select at least one product to apply for after-sales service",
  repaybacking: "Refunding",
  cancelAppealling: "Withdraw Claim",
  sendBack: "Return the product",
  viewSendBackTransform: "View return shipping",
  appealA: "Claim",
  confirmA: "Confirm",
  askCancelAppeal: "Are you sure you want to withdraw your claim?",
  refundDesc: "Refund Description:",
  chineseClass: "Categories in Chinese Version",
  englishClass: "Categories in English Version",
  arClass: "Categories in Arabic Version",
  paySuccess: "Payment successful",
  payFailed: "Payment failed",
  day: "day(s)",
  returnAddress: "Rerurn adress:",
  unchoseGood: "Please select product",
  balance: "wallet balance:",
  viewDetail: "View details",
  currentBalance: "Current balance",
  amountBalance: "Accumulated balance",
  paymentPassword: "Payment password",
  recharge: "Top up",
  earnDetail: "Income details",
  payDetail: "Details of expenditures",
  platAdjust: "Platform adjustment",
  consume: "Consumption",
  trandeType: "Transaction Type",
  adjustReason: "Reason for adjustment:",
  moneyA: "Amount:",
  time: "time:",
  unbindPhone: "Unbound mobile phone number",
  unbindEmail: "Unbound email",
  validEmail: "E-mail verification",
  validPhone: "Mobile phone number verification",
  setPaymentPasswordTip: "Please enter the 6-digit payment password",
  rechargeMoney: "Recharge amount:",
  rechargeMoneyPh: "Please enter the recharge amount",
  paymentOptionsA: "payment method:",
  paginationTip: "Press Enter",
  qicard: "Qi Card",
  unsetPaymentPassword: "Please set your payment password",
  mixPay: "Mixed pay",
  balancePay: "Balance pay",
  currentPoints: "Current Points",
  amountPoints: "Accumulated points",
  RedeemPoints: "Redeem Points",
  Purchase : "Purchase ",
  Share : "Share",
  All:'All',
  Underreview:"Under review",
  Approved:'Approved',
  Reviewrejected:"Review rejected",
  ApprovalStatus:"Approval Status",
  ApplicationTime:"Application Time",
  redeemedNumber:"Redeemed Number",
  Reasonforrejection:"Reason for rejection",
  RedeemAll:"Redeem All",
  EstimatedRedemptionAmount:"Estimated Redemption Amount",
  RedemptionInstructions:"Redemption Instructions",
  canredeempoints:"You can redeem only when you have more than *** points",
  pointConfirm:'Confirm',
  jifen:'points',
  orderUser:"Ordering user",
  OrderNo:'Order Number:',
  orderGoods:"Order goods",
  orderTime:"Order time",
  exchangesuccess:"The redemption application is successful and awaiting review",
  nothaveenoughpoints:'You do not have enough points',
  int_tip:"Please enter an integer",
  email:"Email",
	Contactnumber:"Contact number",
	Message:"Message",
  Sendemail:'Sende mail',
	Pleaseenterthecorrectemailaddress:'Please enter the correct email address',
	Pleasefillintheinformationandsubmit:'Please fill in the information and submit',
  sendsuccess:'Submit successfully',
  username:'Your Name',
  alipay:'AliPay',
  paysuccess:"Confirm payment completed？",
  SystemModification:"System Modification",
  Rechargebalance:'Top up balance'
};
