<template>
    <div class="cards-wrapper">
        <div v-for="item in options" :key="item.value" @click="change(item.value)" class="item"
            :active="value?.some(c => c == item.value)">{{ item.label }}</div>
    </div>
</template>

<script>
export default {
    name: 'Cards',
    methods: {
        /**
         * 值改变
         */
        change(value) {
            let v;
            if (this.single) {
                v = [value];
            }
            else {
                v = this.value ? [...this.value] : [];
                const index = v?.indexOf(value);
                if (index >= 0) {
                    v.splice(index, 1);
                } else {
                    v.push(value);
                }
            }
            this.$emit('input', v);
            this.$emit('change', v);
        }
    },
    props: ['options', 'value', 'single']
}
</script>

<style lang="scss" scoped>
.cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .item {
        cursor: pointer;
        height: 46px;
        border-radius: 4px;
        line-height: 46px;
        text-align: center;
        font-size: 16px;
        position: relative;
        color: #AAAAAA;
        border: 1px solid #DCDCDC;
        white-space: nowrap;
        padding: 0 30px;
        min-width: 150px;

        &[active] {
            background: #CEEAFF;
            border: 1px solid #3EA6F2;
            color: #31A1F3;

            &::after {
                content: '';
                position: absolute;
                display: inline-block;
                width: 24px;
                height: 14px;
                background: url(@/assets/imgs/home/success.png);
                right: 0;
                top: 0;
            }
        }
    }
}
</style>