<template>
    <div class="tabs-wrapper" :style="{ margin }" :ar="$i18n.locale === 'ar'">
        <span @click="$emit('input', key); $emit('change', key)" :active="value == key" v-for="(item, key) in tabs"
            :key="key">{{ item }}</span>
    </div>
</template>

<script>
export default {
    name: 'UserTabs',
    props: {
        /**
         * margin
         */
        margin: String,
        /**
         * 值
         */
        value: [String, Number],
        /**
         * 标签
         */
        tabs: [Object, Array]
    }
}
</script>

<style lang="scss" scoped>
.tabs-wrapper {
    background: white;
    font-size: 20px;
    color: #999999;
    display: flex;
    align-items: center;
    gap: 60px;

    &[ar] {
        direction: rtl;
    }

    span {
        padding-bottom: 11px;
        text-align: center;
        position: relative;
        cursor: pointer;

        &[active],
        &:hover {
            font-weight: 500;
            color: #38A3F1;
            font-family: middle;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 40px;
                height: 4px;
                background: #38A3F1;
                border-radius: 2px;
            }
        }
    }
}
</style>