/**
 * 列表接口文件
 */

import request from './request';

export default {
    /**
     * 商品通用列表
     */
    commoditySearch: model => request.post('/tables/commoditySearch', model, { no: true }),
    /**
     * 品牌列表
     */
    brandList: model => request.post('/tables/brandList', model, { no: true }),
    /**
     * 门店列表
     */
    shopSearch: model => request.post('/tables/shopSearch', model, { no: true }),
    /**
     * 活动列表
     */
    activititySearch: model => request.post('/tables/activititySearch', model, { no: true }),
    /**
     * 优惠券列表
     */
    discountList: model => request.post('/tables/discountList', model, { no: true }),
    /**
     * 商品评论列表
     */
    commodityAccess: model => request.post('/tables/commodityAccess', model, { no: true }),
    /**
     * 领取优惠券
     */
    getDiscount: discountId => request.post('/tables/getDiscount', { discountId }),
    /**
     * 获取已提交的商家申请信息
     */
    getBusinessInfo: () => request.post('/tables/getBusinessInfo', {}, { no: true }),
    /**
     * 提交/修改提交成为商家
     */
    applyBusiness: model => request.post('/tables/applyBusiness', model),
    /**
     * 活动详情
     */
    activitityDetail: model => request.post('/tables/activitityDetail', model, { no: true }),
    /**
     * 平台切换语言后获取的分类
     */
    otherLanguageCateGoryId: categoryId => request.get('/tables/otherLanguageCateGoryId', { params: { categoryId }, no: true }),
    /**
     * 商家切换语言后获取的分类
     */
    otherLanguageShopCateGoryId: categoryId => request.get('/tables/otherLanguageShopCateGoryId', { params: { categoryId }, no: true }),
}