<template>
    <div class="list-wrapper">
        <HomeGoodsList hideMore :title="$t('hotRecommend')" :list="$store.state.home?.recommendCommoditys" />
        <HomeGoodsList :cid="item.categoryId" :title="item.categoryName"
            v-for="item in $store.state.home?.firstLevelCommoditys" :key="item.categoryId" :list="item.bsCommodities" />
    </div>
</template>

<script>
export default {
    name: 'List'
}
</script>

<style lang="scss" scoped>
.list-wrapper {
    padding-top: 40px;

    &>div {
        padding-bottom: 30px;
    }
}
</style>