<template>
    <a-form-model hideRequiredMark :model="model" v-bind="$attrs" v-on="$listeners" :colon="false"
        :ar="$i18n.locale === 'ar'" :labelAlign="$i18n.locale === 'ar' ? 'right' : 'left'" ref="form">
        <a-form-model-item :rules="item.rules" :style="item.style" v-for="(item, key) in opts" :key="key"
            :label="item.title" :labelAlign="item.align" :wrapperCol="{ style: { width: item.width, ...item.ws } }"
            :labelCol="{ style: { width: item.lw || lw } }" :prop="key">
            <!-- 输入框 -->
            <Input v-if="item.tag === 'input'" v-model="model[key]" :maxLength="item.max" :type="item.type"
                :placeholder="item.ph" :disabled="item.dis" :addonAfter="item.after" />
            <!-- 下拉选项 -->
            <a-select v-else-if="item.tag === 'select'" v-model="model[key]" :placeholder="item.ph">
                <a-select-option v-for="child in item.values" :key="child.value">
                    {{ child.label }}
                </a-select-option>
            </a-select>
            <!-- 时间 -->
            <DatePicker v-else-if="item.tag === 'date'" v-model="model[key]" :placeholder="item.ph" />
            <!-- 按钮 -->
            <Button v-else-if="item.tag === 'button'" :shape="item.shape" :type="item.type" :htmlType="item.htmlType">{{
                item.text }}</Button>
            <!-- textarea -->
            <TextArea v-else-if="item.tag === 'textarea'" :width="item.width" :height="item.height" :disabled="item.dis"
                :style="item.style" :placeholder="item.ph" v-model="model[key]"></TextArea>
            <!-- 图片上传组件 -->
            <Uploder v-else-if="item.tag === 'uploder'" v-model="model[key]" :width="item.width" :style="item.style"
                :size="item.size" :max="item.max" />
            <!-- 手机号 -->
            <PhoneNumber v-else-if="item.tag === 'phone'" :readonly="item.readonly" :dis="item.dis" v-model="model[key]"
                :style="item.style" :ph="item.ph" :options="item.options" />
            <!-- 地址 -->
            <Address v-else-if="item.tag === 'address'" v-model="model[key]" :style="item.style" />
            <!-- 卡片选择 -->
            <Cards v-else-if="item.tag === 'cards'" v-model="model[key]" :style="item.style" :options="item.options"
                :single="item.single" />
            <!-- brRow -->
            <BrRow v-else-if="item.tag === 'brRow'" :label="item.label" v-model="model[key]" :child="item.child"
                :max="item.max" :style="item.style" />
            <!-- Extra -->
            <Extra v-else-if="item.tag === 'extra'" v-model="model[key]" :label="item.label" :extra="item.extra"
                :child="item.child" :options="item.options" :single="item.single" />
            <!-- 自定义 -->
            <slot :name="key" :data="model[key]"></slot>
        </a-form-model-item>
        <a-form-model-item v-if="submit">
            <div class="buttons">
                <Button shape="round" htmlType="submit" width="340px" r26 height="56px">{{ typeof submit === 'string' ?
                    submit : $t('confirm')
                }}</Button>
            </div>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import { Input, DatePicker } from 'ant-design-vue';
import PhoneNumber from './components/PhoneNumber';
import Address from './components/Address.vue';
import Cards from './components/Cards.vue';
import Uploder from './components/ImageUploder.vue';
import BrRow from './components/BrRow';
import Extra from './components/Extra.vue';

/**
 * 替换规则消息
 */
const replaceMessage = (message, title, min, max) => message.replace('{t}', title).replace('{min}', min).replace('{max}', max);
/**
 * 是否为数字
 */
const isNumber = value => typeof value === 'number';
export default {
    name: 'BigForm',
    methods: {
        /**
         * 校验
         */
        validate() {
            return this.$refs.form.validate();
        }
    },
    components: { Input, DatePicker, PhoneNumber, Address, Cards, Uploder, BrRow, Extra },
    props: {
        /**
         * 提交按钮
         */
        submit: [Boolean, String],
        /**
         * 配置项
         */
        options: {
            required: true,
            type: Object,
            default: () => ({})
        },
        /**
         * label宽度
         */
        lw: {
            type: String,
            default: '96px'
        },
        /**
         * 对象
         */
        model: {
            required: true,
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * 配置项
         */
        opts() {
            const options = {};
            for (const key in this.options) {
                const item = { ...this.options[key] };
                options[key] = item;
                item.tag = item.tag || 'input';
                item.rules = item.rules || [];
                if (item.required && !item.rules.some(c => c.required)) {
                    item.rules.unshift({ required: true });
                }

                for (const rule of item.rules) {
                    rule.trigger = 'change';
                    if (rule.relate) {
                        const { relate } = rule;
                        rule.validator = (_, value) => {
                            const r = this.model[relate];
                            if ((r || '') === (value || '')) {
                                return Promise.resolve();
                            }
                            return Promise.reject(rule.message);
                        }
                        delete rule.relate;
                    }
                    if (rule.message) continue;
                    const { min, max } = rule;
                    if (rule.required) {
                        rule.message = replaceMessage(this.$t('required'), item.title || item.label);
                    }
                    else if (isNumber(min) && isNumber(max)) {
                        rule.message = replaceMessage(this.$t('minMax'), item.title, min, max);
                    }
                    else if (isNumber(min)) {
                        rule.message = replaceMessage(this.$t('min'), item.title, min, max);
                    }
                    else if (isNumber(max)) {
                        rule.message = replaceMessage(this.$t('max'), item.title, min, max);
                    }
                }
            }
            return options;
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-form {
    margin-bottom: -24px;

    &[ar] {
        direction: rtl;
    }

    ::v-deep .ant-row {
        display: flex;
        margin-bottom: 30px;

        &.ant-form-item-with-help {
            margin-bottom: 13px;
        }

        .ant-form-item-label {
            line-height: 52px;

            label {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                font-family: middle;
            }
        }

        .ant-form-item-control-wrapper {
            flex: 1;

            .ant-form-item-children {
                display: flex;
                align-items: center;

                .buttons {
                    width: 100%;
                    text-align: center;
                }

                ::placeholder {
                    font-size: 18px;
                    color: #999999;
                }

                input {
                    height: 52px;
                    padding: 0 33px;
                    font-size: 18px;
                }

                .ant-select-selection {
                    height: 52px;

                    .ant-select-selection__rendered {
                        line-height: 52px;
                        font-size: 18px;

                        .ant-select-selection-selected-value {
                            line-height: 52px;
                            padding-left: 22px;
                            font-family: middle;
                            font-weight: 500;
                            color: #333333;
                        }

                        .ant-select-selection__placeholder {
                            padding-left: 22px;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
}
</style>