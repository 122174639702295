/**
 * 首页接口文件
 */

import request from './request';
import store from '@/store';

export default {
    /**
     * 获取首页数据
     */
    index: () => request.post('index/getAllIndexData', { id: 1 }, { no: true }),
    /**
     * 获取平台商品类别(根据顶级/获取下级分类)
     */
    getCommodityCategory: model => request.post('index/getCommodityCategory', model, { no: true }),
    /**
     * 根据id获取设置信息(富文本)
     * 1：积分规则
     * 3：常见问题
     * 4：站点地图
     * 5：隐私条款
     * 6:账单条款及细则
     * 7:商户入驻协议
     */
    getInfo: infoSetId => request.post('index/getInfo', { infoSetId }, { no: true }),
    /**
     * 心愿单/购物车/收藏夹数量
     */
    infoCounts: () => request.post('index/infoCounts', { tmpUserId: store.state.tmpUserId }, { no: true }),
    /**
     * 常见问题
     */
    problem: () => request.get('index/problem', { no: true }),
    /**
     * 货币列表
     */
    currency: () => request.get('index/currency', { no: true }),
    /**
     * 获取店铺类型
     */
    getShopType: () => request.post('index/getShopType', {}, { no: true })
}