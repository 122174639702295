<template>
    <div class="tabs-wrapper" :ar="$i18n.locale === 'ar'">
        <span @click="$emit('input', key); $emit('change', key)" :active="value == key" v-for="(key, item) in tabs"
            :key="key">{{ item }}</span>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        /**
         * 值
         */
        value: [String, Number],
        /**
         * 标签
         */
        tabs: [Object, Array]
    }
}
</script>

<style lang="scss" scoped>
.tabs-wrapper {
    height: 58px;
    background: white;
    font-size: 18px;
    color: #999999;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;

    &[ar] {
        direction: rtl;

        span {
            &:not(:last-child)::after {
                right: unset;
                left: 0;
            }
        }
    }

    span {
        display: inline-block;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        // width: 142px;
        padding: 0 20px;
        text-align: center;
        box-sizing: border-box;

        &[active],
        &:hover {
            font-size: 20px;
            font-weight: 500;
            color: #1C9FFD;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 40px;
                height: 2px;
                background: #1C9FFD;
                border-radius: 1px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &:not(:last-child)::after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 26px;
            background: #DFDFDF;
            opacity: 0.5;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>