import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css';
import i18n from './i18n';
import './styles/index.scss';
import components from './components';
import Mixins from '@/utils/mixins';
import dayjs from 'dayjs';

/**
 * dayjs扩展
 */
const dayjsExtends = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype.formatD = function () {
    return this.format('YYYY-MM-DD');
  }
  dayjsClass.prototype.toString = function () {
    return this.formatD();
  }
}
dayjs.extend(dayjsExtends);
Vue.config.productionTip = false;
Vue.use(components);
Vue.mixin(Mixins);

new Vue({
  router,
  store,
  i18n: i18n(Vue),
  render: h => h(App)
}).$mount('#app')