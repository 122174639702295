<template>
  
    <router-link class="card" tag="div" :ar="$i18n.locale === 'ar'"
        :to="{ name: 'goodsDetail', params: { commodityId: goods?.commodityId } }">
        <div class="pic">
            <img :src="splitImage(goods?.commodityUrls)" alt="">
        </div>
        <div class="title">{{ goods?.commodityName }}</div>
        <footer>
            <div>
                <p>
                    <Price :price="goods?.salsePrice" :markSize="20" :priceSize="24" />
                </p>
                <s><Price :price="goods?.price" :priceSize="16" :markSize="16" color="#999999" /></s>
                <!-- <s><i>{{ $t('moneyMark') }}</i>&nbsp;{{ typeof goods?.price === 'number' ? goods?.price.toFixed(2) :
                    goods?.price }}</s> -->
            </div>
            <img @click.stop="collect" :src="!goods?.collectMark ? collected : unCollect" alt="">
        </footer>
    </router-link>
  
</template>

<script>
import api from '@/api/detail';

export default {
    name: 'PartyEntryGoodsCard',
    data() {
        return {
            /**
             * 已收藏图标
             */
            collected: require('@/assets/imgs/home/collect.png'),
            /**
             * 未收藏图标
             */
            unCollect: require('@/assets/imgs/home/uncollect.png')
        }
    },
    methods: {
        /**
         * 收藏商品
         */
        async collect() {
            try {
                await api.commodityCollect({
                    commodityId: this.goods?.commodityId
                });
                this.goods.collectMark = !this.goods.collectMark;
                this.$store.dispatch('getInfoCounts');
            } catch (error) { }
        }
    },
    props: {
        /**
         * 商品信息
         */
        goods: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'var';

.card {
    display: inline-block;
    width: 285px;
    height: 445px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid white;
    transition: .2s linear;
    box-shadow: -1px 3px 10px 0px rgba(59, 95, 169, 0.09);

    &[ar] {
        direction: rtl;
    }

    &:hover {
        border-color: #41A7F3;
        box-shadow: -1px 1px 7px 0px rgba(65, 167, 243, 0.32);
    }

    .pic {
        height: 285px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;

        img {
            width: 80%;
            max-height: 85%;
        }
    }

    .title {
        padding-left: 17px;
        padding-right: 15px;
        font-size: 18px;
        color: #333333;
        line-height: 32px;
        min-height: 64px;
        @include line(2);
    }

    footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 21px 20px 0 20px;

        &>div {
            padding-bottom: 1px;

            s {
                font-size: 16px;
                font-weight: 500;
                color: #999999;
                display: flex;

                i {
                    font-style: normal;
                }
            }

            p {
                font-size: 20px;
                color: #E60013;
                margin-bottom: 12px;
            }
        }

        img {
            cursor: pointer;
        }
    }
}
</style>