<template>
    <div class="extra-wrapper">
        <div class="title" :ar="$i18n.locale === 'ar'">
            <h1>{{ label }}</h1>
            <p>{{ extra }}</p>
        </div>
        <div class="content">
            <!-- 图片上传组件 -->
            <Cards v-if="child === 'cards'" v-on="$listeners" v-bind="$attrs" />
        </div>
    </div>
</template>

<script>
/**
 * 额外标题行
 */
import Cards from './Cards.vue';

/**
 * 换行label的row组件
 */
export default {
    name: 'Extra',
    inheritAttrs: false,
    components: { Cards },
    props: ['label', 'extra', 'child']
}
</script>

<style lang="scss" scoped>
.extra-wrapper {
    display: flex;
    width: 100%;

    .title {
        padding-right: 78px;

        &[ar] {
            padding: 0 0 0 118px;
        }

        h1 {
            font-size: 18px;
            font-weight: 500;
            font-family: middle;
            color: #333333;
        }

        p {
            font-size: 14px;
            color: #666666;
            font-family: normal;
            text-align: center;
            padding-top: 10px;
        }
    }

    .content {
        flex: 1;
    }
}
</style>