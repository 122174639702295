<template>
    <a-form-model ref="form" :model="model" v-bind="$attrs" v-on="$listeners" :layout="layout" :colon="false"
        :noRadius="noRadius" :ar="$i18n.locale === 'ar'">
        <a-form-model-item :rules="item.rules" :style="item.itemStyle" v-for="(item, key) in opts" :key="key"
            :label="item.title" :labelAlign="item.align || 'left'"
            :wrapperCol="{ style: { width: item.tag !== 'daterange' && (item.width || ww) } }"
            :labelCol="{ style: { width: item.lw || lw } }" :prop="key">
            <!-- 输入框 -->
            <Input v-if="item.tag === 'input'" v-model="model[key]" :maxLength="item.max" :type="item.type"
                :size="item.size" :placeholder="item.ph" :disabled="item.dis" />
            <!-- 数字输入框 -->
            <InputNumber v-else-if="item.tag === 'number'" v-model="model[key]" :max="item.max" :min="item.min"
                :placeholder="item.ph" :size="item.size" :style="{ width: item.width, ...item.style }" />
            <!-- 下拉选项 -->
            <a-select v-else-if="item.tag === 'select'" :allowClear="item.clear" v-model="model[key]"
                :size="item.size || 'large'" :placeholder="item.ph">
                <a-select-option v-for="child in item.values" :key="child.value">
                    {{ child.label }}
                </a-select-option>
            </a-select>
            <!-- 时间 -->
            <DatePicker v-else-if="item.tag === 'date'" v-model="model[key]" size="large" :placeholder="item.ph" />
            <!-- 时间范围选择器 -->
            <DateRange v-else-if="item.tag === 'daterange'" v-model="model" />
            <!-- 按钮 -->
            <Button v-else-if="item.tag === 'button'" :htmlType="item.htmlType">{{ item.text }}</Button>
            <!-- textarea -->
            <TextArea v-else-if="item.tag === 'textarea'" :width="item.width" :rows="item.rows" :height="item.height"
                :disabled="item.dis" :style="item.style" :placeholder="item.ph" v-model="model[key]"></TextArea>
            <!-- 图片上传组件 -->
            <ImageUploder v-else-if="item.tag === 'uploder'" v-model="model[key]" :width="item.width" :style="item.style"
                :size="item.size" :multi="item.multi" />
            <!-- 自动上传图片组件 -->
            <BigImageUploder v-else-if="item.tag === 'iu'" v-model="model[key]" :hideText="item.hideText" :wh="item.wh"
                :size="item.size" :max="item.max" />
            <!-- 地址 -->
            <Address v-else-if="item.tag === 'address'" v-model="model[key]" :style="item.style" />
            <!-- 自定义 -->
            <slot :name="key" :data="model[key]"></slot>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import { Input, DatePicker, InputNumber } from 'ant-design-vue';
import Address from '../BigForm/components/Address.vue';
import DateRange from './components/DateRange';
import BigImageUploder from '../BigForm/components/ImageUploder.vue';

/**
 * 替换规则消息
 */
const replaceMessage = (message, title, min, max) => message.replace('{t}', title).replace('{min}', min).replace('{max}', max);
/**
 * 是否为数字
 */
const isNumber = value => typeof value === 'number';
/**
 * 是否为空
 */
const isEmpty = v => null === v || undefined === v || (typeof v === 'string' && '' === v.trim()) || (Array.isArray(v) && v.length === 0) || (Object.prototype.toString.call(v) === '[object Object]' && Object.keys(v).length === 0);
/**
 * 自定义校验标签
 */
const customValidateTags = ['address', 'select', 'iu', 'number'];

export default {
    name: 'Form',
    components: { Input, DatePicker, DateRange, Address, BigImageUploder, InputNumber },
    props: {
        /**
         * 没有圆角
         */
        noRadius: {
            type: Boolean,
            default: true
        },
        /**
         * 配置项
         */
        options: {
            required: true,
            type: Object,
            default: () => ({})
        },
        /**
         * 布局
         */
        layout: {
            type: String,
            default: 'inline'
        },
        /**
         * label宽度
         */
        lw: {
            type: String,
            default: '77px'
        },
        /**
         * 内容区宽度
         */
        ww: {
            type: String,
            default: '300px'
        },
        /**
         * 对象
         */
        model: {
            required: true,
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        /**
         * 重置
         */
        reset() {
            return this.$refs.form.resetFields();
        },
        /**
         * 校验
         */
        validate() {
            return this.$refs.form.validate();
        }
    },
    computed: {
        /**
         * 配置项
         */
        opts() {
            const options = {};
            for (const key in this.options) {
                const item = { ...this.options[key] };
                options[key] = item;
                item.tag = item.tag || 'input';
                item.rules = item.rules || [];
                if (item.required && !item.rules.some(c => c.required)) {
                    item.rules.unshift({ required: true });
                }

                for (const rule of item.rules) {
                    rule.trigger = 'change';
                    rule.whitespace = true;
                    if (rule.required && customValidateTags.includes(item.tag)) {
                        rule.validator = (rule, value) => {
                            if (isEmpty(value)) {
                                return Promise.reject(rule.message || replaceMessage(this.$t('required'), item.title));
                            }
                            return Promise.resolve();
                        }
                        continue;
                    }
                    if (rule.message) continue;
                    const { min, max } = rule;
                    if (rule.required) {
                        rule.message = replaceMessage(this.$t('required'), item.title);
                    }
                    else if (isNumber(min) && isNumber(max)) {
                        rule.message = replaceMessage(this.$t('minMax'), item.title, min, max);
                    }
                    else if (isNumber(min)) {
                        rule.message = replaceMessage(this.$t('min'), item.title, min, max);
                    }
                    else if (isNumber(max)) {
                        rule.message = replaceMessage(this.$t('max'), item.title, min, max);
                    }
                }
            }
            return options;
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-form {
    &[ar] {
        direction: rtl;
    }

    &[noRadius] {
        ::v-deep .ant-form-item-control {

            input,
            textarea,
            .ant-select,
            .ant-select-selection {
                border-radius: 0;
            }
        }
    }

    ::v-deep .ant-form-item-control {

        textarea {
            border: 1px solid #d9d9d9;
            padding: 10px;
            background: transparent;
            font-size: 16px;
            color: #999999;
        }

        .ant-input-group {
            &>* {
                float: left;
            }
        }
    }

    &.ant-form-horizontal {
        ::v-deep .ant-form-item {
            display: flex;

            .ant-form-item-label {
                label {
                    font-size: 16px;
                    color: #666666;
                }
            }

            .ant-select-selection-selected-value {
                line-height: 40px;
            }
        }
    }

    &.ant-form.ant-form-inline {
        .ant-form-item {
            margin: 0 30px 30px 0;

            ::v-deep .ant-form-item-label {
                label {
                    font-size: 16px;
                    color: #333333;
                }
            }

            ::v-deep .ant-form-item-control-wrapper {
                input {
                    font-size: 16px;
                    height: 42px;
                    padding-left: 21px;
                }

                .ant-form-explain {
                    padding-top: 5px;
                    margin-bottom: -24px;
                }

                .ant-select-selection {
                    height: 42px;

                    .ant-select-selection__rendered {
                        height: 42px;
                        margin-left: 21px;

                        .ant-select-selection__placeholder {
                            line-height: 18px;
                            color: #999999;
                        }

                        .ant-select-selection-selected-value {
                            line-height: 40px;
                        }
                    }
                }
            }
        }
    }
}
</style>