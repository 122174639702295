<template>
    <div class="divider-wrapper" :style="{ margin, width: typeof width === 'string' ? width : width + 'px' }">
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'Divider',
    props: ['margin', 'width']
}
</script>

<style lang="scss" scoped>
.divider-wrapper {
    position: relative;
    margin: 0 auto;
    color: #999999;
    text-align: center;
    height: 14px;

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background: #EEEEEE;
        transform: translateY(-50%);
        z-index: 1;
    }

    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate((-50%, -50%));
        background: white;
        padding: 0 10px;
        z-index: 2;
    }
}
</style>