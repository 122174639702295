import zh from "./zh";
import en from "./en";
import ar from "./ar";
import VueI18n from "vue-i18n";

/**
 * 语言key
 */
const languageKey = "System.locale";
let language = localStorage.getItem(languageKey);
if (!language) {
  language = (
    window.navigator.language || window.navigator.browserLanguage
  ).toLowerCase();
  if (language.includes("ar")) {
    language = "ar";
  } else if (language.includes("en")) {
    language = "en";
  } else {
    language = "zh";
  }
  localStorage.setItem(languageKey, language);
}

/**
 * 消息列表
 */
export const messages = {
  zh,
  en,
  ar,
};

export { languageKey };

export default (Vue) => {
  Vue.use(VueI18n);
  // 通过选项创建 VueI18n 实例
  const i18n = new VueI18n({
    locale: language || "zh", // 设置地区
    messages, // 设置地区信息
  });
  return i18n;
};
