<template>
    <Input :max="11" direction="ltr" :readonly="readonly" :type="hide ? 'text' : 'number'" v-model="model.tel"
        :prefix="require('@/assets/imgs/users/phone.png')" :ph="$t('phonePh')">
    <template v-slot:prefix>
        <ASelect :disabled="readonly" v-model="model.areaCode" option-label-prop="label">
            <ASelectOption :value="item.mobilePrefix" :label="item.mobilePrefix" v-for="item in list" :key="item.id">
                <img width="20" height="20" :src="item.banner" />
                <span role="img">
                    {{ item.mobilePrefix }}
                </span>
            </ASelectOption>
        </ASelect>
    </template>
    </Input>
</template>

<script>
import { message } from 'ant-design-vue';
import api from '@/api/detail';

export default {
    name: 'PhoneNumber',
    data() {
        let value = this.value;
        if (this.hide) {
            const s = value.slice(0, -8);
            const e = value.slice(-4);
            value = `${s}****${e}`;
        }
        return {
            /**
             * 对象
             */
            model: {
                areaCode: this.hide ? this.$store.state.user.areaCode : this.$i18n.locale == 'ar' ? '+964' : '+86',
                tel: value || ''
            },
            /**
             * code列表
             */
            list: []
        }
    },
    computed: {
        /**
         * 编码
         */
        codes() {
            return this.list.map(c => ({ label: c, value: c }));
        },
        /**
         * 用户信息
         */
        user() {
            return this.$store.state.user;
        }
    },
    async created() {
        if (this.auto) {
            Object.assign(this.model, {
                areaCode: this.user.areaCode,
                tel: this.user.tel
            });
            return this.list = [this.user.areaCode];
        }
        try {
            this.list = await api.areaCode();
        } catch (error) { }
    },
    props: {
        value: String,
        readonly: Boolean,
        hide: Boolean,
        auto: Boolean
    },
    methods: {
        /**
         * 验证数据
         */
        valid() {
            for (const [key, item] of Object.entries({ tel: 'phone' })) {
                if (!this.model[key]?.trim()) {
                    message.warn(this.$t('required').replace('{t}', this.$t(item)));
                    return Promise.reject();
                }
            }
            let { tel } = this.model;
            if (this.hide) {
                tel = this.value;
            }
            if (!/^\d+$/.test(tel)) {
                message.warn(this.$t('invalidPhone'));
                return Promise.reject();
            }
            return { ...this.model, tel };
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-select {
    width: 120px;

    ::v-deep .ant-select-selection {
        background: transparent;
        border: none;
        box-shadow: none;

        .ant-select-selection__rendered {
            line-height: 30px;
            margin: 0;

            .ant-select-selection-selected-value {
                line-height: 30px;
                font-size: 18px;
                color: #333333;
            }
        }
    }
}
</style>