/**
 * 详情接口文件
 */

import request from './request';

export default {
    /**
     * 店铺主页详情
     */
    shopIndex: model => request.post('/detail/shopIndex', model, { no: true }),
    /**
     * 关注/取消关注店铺
     */
    shopCollect: model => request.post('/detail/shopCollect', model),
    /**
     * 添加店铺留言
     */
    addShopLeaveMessage: model => request.post('/detail/addShopLeaveMessage', model),
    /**
     * 优惠券关联商品信息查询
     */
    discountToCommodity: model => request.post('/detail/discountToCommodity', model, { no: true }),
    /**
     * 商品详情
     */
    commodityIndex: model => request.post('/detail/commodityIndex', model, { no: true }),
    /**
     * 收藏/取消收藏商品
     */
    commodityCollect: model => request.post('/detail/commodityCollect', model),
    /**
     * 加入/取消加入心愿单商品
     */
    addToWishCollect: model => request.post('/detail/addToWishCollect', model),
    /**
     * 商品评论
     */
    commodityAccess: model => request.post('/detail/commodityAccess', model, { no: true }),
    /**
     * 获取区号
     */
    areaCode: () => request.get('detail/areaCode', { no: true })
}