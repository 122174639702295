<template>
    <div class="wrapper">
        <img @click="slide(index + 1)" src="@/assets/imgs/home/组 3.png" alt="" v-if="length > 3">
        <img @click="slide(index - 1)" src="@/assets/imgs/home/组 3.png" alt="" v-if="length > 3">
        <div class="box" ref="box" :style="{ width: total * 1221 + 'px', transform: `translateX(-${1221 * index}px)` }">
            <img @click="$router.push({ name: 'partyDetail', params: { id: item.activityId } })"
                v-for="item in $store.state.home?.activitites" :key="item.activityId" :src="item.activityUrl" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name: 'Slider',
    data() {
        return {
            /**
             * 下标
             */
            index: 0,
            /**
             * 子元素总数
             */
            total: 0
        }
    },
    methods: {
        /**
         * 滑动
         */
        slide(index) {
            this.index = Math.min(this.total - 1, Math.max(0, index));
        }
    },
    computed: {
        length() {
            return this.$store.state.home?.activitites?.length || 0;
        }
    },
    mounted() {
        this.total = Math.ceil(this.$refs.box.children.length / 3);
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
    margin-top: 30px;

    &>img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 10;
        cursor: pointer;

        &+img {
            left: unset;
            right: 0;
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .box {
        display: flex;
        transition: transform .5s linear;

        img {
            cursor: pointer;
            width: 387px;
            height: 210px;

            &+img {
                margin-left: 20px;
            }
        }
    }
}
</style>