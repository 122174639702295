<template>
    <div class="increment-wrapper">
        <div @click="change(1)">
            <img src="@/assets/imgs/home/plus.png" alt="">
        </div>
        <input @change="e => change(e)" :value="value" type="text">
        <div @click="change(-1)">
            <img src="@/assets/imgs/home/reduce.png" alt="">
        </div>
    </div>
</template>

<script>
/**
 * 递增器
 */
export default {
    name: 'Incrementer',
    methods: {
        /**
         * 修改值
         */
        change(e) {
            const oldV = this.value;
            let value = e * 1;
            if (typeof e === 'object') {
                value = e.target.value * 1;
            } else {
                value += this.value * 1;
            }
            if (typeof value === 'number' && (value + '') !== 'NaN') {
                value = Math.max(1, value);
                if (oldV === value) return;
                this.$emit('input', value);
                this.$emit('change', value);
            } else {
                e.target.value = this.value;
            }
        }
    },
    props: {
        /**
         * 值
         */
        value: Number
    }
}
</script>

<style lang="scss" scoped>
.increment-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 3px;

    input {
        display: inline-block;
        text-align: center;
        width: 54px;
        height: 35px;
        background: #FFFFFF;
        border: 1px solid #C9C9C9;
        outline: none;
    }

    div {
        width: 38px;
        height: 35px;
        background: #FFFFFF;
        border: 1px solid #C9C9C9;
        opacity: 0.96;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
</style>