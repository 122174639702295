<template>
    <div class="datepicker-wrapper" :ar="$i18n.locale === 'ar'">
        <DatePicker v-model="value.startTime" :placeholder="$t('startTime')" size="large" />
        <span v-t="'to'" class="split"></span>
        <DatePicker v-model="value.endTime" :placeholder="$t('endTime')" size="large" />
    </div>
</template>

<script>
import { DatePicker } from 'ant-design-vue';
export default {
    name: 'DateRange',
    props: {
        /**
         * 对象
         */
        value: {
            required: true,
            type: Object
        }
    },
    components: { DatePicker }
}
</script>

<style lang="scss" scoped>
.datepicker-wrapper {
    &[ar] {
        ::v-deep .ant-calendar-picker {
            input {
                padding-left: unset;
                padding-right: 21px;
            }

            .ant-calendar-picker-icon {
                right: unset;
                left: 12px;
            }
        }
    }

    .ant-calendar-picker {
        width: 200px;
    }

    .split {
        padding: 0 15px;
    }
}
</style>