/**
 * 用户接口文件
 */

import request from './request';
import store from '@/store';

export default {
    /**
     * 获取验证码
     */
    getCaptcha: model => request.post('/user/getMessage', model),
    /**
     * 注册
     */
    register: model => request.post('/user/userRegist', model),
    /**
     * 重设密码
     */
    resetPassword: model => request.post('/user/resetPassword', model),
    /**
     * 用户登录
     */
    userLogin: model => request.post('/user/userLogin', model),
    /**
     * 修改用户信息
     */
    updateInfo: model => request.post('/user/updateInfo', model),
    /**
     * 更换手机号
     */
    editTel: model => request.post('/user/editTel', model),
    /**
     * 增加用户地址
     */
    addUserAddress: model => request.post('/user/addUserAddress', { ...model, tmpUserId: store.state.tmpUserId }),
    /**
     * 更新用户地址
     */
    updateUserAddress: model => request.post('/user/updateUserAddress', { ...model, tmpUserId: store.state.tmpUserId }),
    /**
     * 获取用户地址
     */
    getUserAddressList: model => request.post('/user/getUserAddressList', { ...model, tmpUserId: store.state.tmpUserId }, { no: true }),
    /**
     * 省市区
     */
    areaList: model => request.post('/user/areaList', model, { no: true }),
    /**
     * 设置默认地址
     */
    setUserDefaultAddressById: model => request.post('/user/setUserDefaultAddressById', { ...model, tmpUserId: store.state.tmpUserId }),
    /**
     * 获取默认收货地址
     */
    getUserDefaultAddress: () => request.post('/user/getUserDefaultAddress', {}, { no: true }),
    /**
     * 删除地址
     */
    removeAddressById: model => request.post('/user/removeAddressById', model),
    /**
     * 获取用户消息列表
     */
    messages: model => request.post('/user/messages', model, { no: true }),
    /**
     * 绑定手机
     */
    relationTel: model => request.post('/user/relationTel', model),
    /**
     * 绑定邮箱
     */
    relationMail: model => request.post('/user/relationMail', model),
    /**
     * 收藏夹列表
     */
    commodityCollectList: model => request.post('/user/commodityCollectList', model, { no: true }),
    /**
     * 用户个人数量展示
     */
    userCounts: () => request.post('/user/userCounts', {}, { no: true }),
    /**
     * 心愿单列表
     */
    commodityWishList: model => request.post('/user/commodityWishList', model, { no: true }),
    /**
     * 批量取消收藏商品
     */
    cancleCollects: model => request.post('/user/cancleCollects', model),
    /**
     * 用户优惠券
     */
    discountList: model => request.post('/user/discountList', model, { no: true }),
    /**
     * 批量取消心愿单
     */
    cancleWishs: model => request.post('/user/cancleWishs', model),
    /**
     * 心愿单下单记录
     */
    wishOrders: model => request.post('/user/wishOrders', model, { no: true }),
    /**
    * 批量加入心愿单
    */
    addWishs: model => request.post('/user/addWishs', model),
    /**
    * 用户商品可用优惠券
    */
    discountCanList: commodityId => request.post('/user/discountCanList', { commodityId }, { no: true }),
    /**
    * 批量加入收藏
    */
    addCollects: model => request.post('/user/addCollects', model),
    /**
     * facebook登录
     */
    facebook: id => request.post('/user/faceBookLogin', { id }, { no: true }),
    /**
     * googleLogin登录
     */
    googleLogin: id => request.post('/user/googleLogin', { id }, { no: true }),
    /**
     * twitterLogin登录
     */
    twitterLogin: id => request.post('/user/twitterLogin', { id }, { no: true }),
    /**
     * 余额明细
     */
    balanceDetail: model => request.post('/order/balanceDetail', model, { no: true }),
    /**
     * 获取验证码
     */
    getMessage: model => request.post('/user/getMessage', model),
    /**
     * 是否设置支付密码
     */
    isSetPassword: () => request.get('/user/isSetPassword', { no: true }),
    /**
     * 设置支付密码
     */
    setPayPassword: model => request.post('/user/setPayPassword', model),
    /**
     * 当前积分和累计积分
     */
    myPoint: model => request.get('/user/myPoint', model,{ no: true }),
    
    /**
     * 积分明细
     */
    pointRecord: model => request.post('/user/point/record', model,{ no: true }),
    /**
     * 兑换规则
     */
    pointRule: model => request.get('/user/point/rule', model,{ no: true }),    
    /**
     * 兑换积分申请
     */
    exchange: model => request.post('/user/point/exchange', model,{ no: true }),    

    /**
     * 兑换积分申请
     */
    problemFeedback: model => request.post('/user/problemFeedback', model,{ no: true }),   
    
}