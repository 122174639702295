<template>
    <div class="sort-wrapper" @click="$emit('input', value == 1 ? 2 : 1); $emit('change');" :ar="$i18n.locale === 'ar'">
        <span>{{ title }}</span>
        <img :src="value == 1 ? asc : desc" alt="">
    </div>
</template>

<script>
/**
 * 正序图片
 */
const asc = require('@/assets/imgs/home/asc.png');
/**
 * 倒序图片
 */
const desc = require('@/assets/imgs/home/desc.png');
export default {
    name: 'Sort',
    data() {
        return {
            asc,
            desc
        }
    },
    props: {
        /**
         * 标题
         */
        title: String,
        /**
         * 是否为正序
         */
        value: Number
    }
}
</script>

<style lang="scss" scoped>
.sort-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #555555;
    gap: 6px;

    &[ar] {
        direction: rtl;
    }
}
</style>