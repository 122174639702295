<template>
    <div class="br-row-wrapper">
        <h1>{{ label }}</h1>
        <div class="content" :ar="$i18n.locale === 'ar'">
            <!-- 图片上传组件 -->
            <Uploder v-if="child === 'uploder'" v-on="$listeners" v-bind="$attrs" />
        </div>
    </div>
</template>

<script>
import Uploder from './ImageUploder.vue';

/**
 * 换行label的row组件
 */
export default {
    name: 'BrRow',
    inheritAttrs: false,
    components: { Uploder },
    props: ['label', 'child']
}
</script>

<style lang="scss" scoped>
.br-row-wrapper {
    h1 {
        font-size: 18px;
        font-weight: 500;
        font-family: middle;
        color: #333333;
    }

    .content {
        padding: 20px 0 0 150px;

        &[ar] {
            padding: 20px 190px 0 0;
        }
    }
}
</style>