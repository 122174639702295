/**
 * 公共组件
 */

import { Icon, Badge, Carousel, DatePicker, Pagination, Popconfirm, Empty, Checkbox, FormModel, Select, Tabs, Space, Radio, Input, Row, Col, Table, TreeSelect, InputNumber, Timeline } from 'ant-design-vue';
import Category from './Category';
import HomeGoodsCard from './HomeGoodsCard';
import HomeGoodsList from './HomeGoodsList';
import Navigator from './Navigator';
import Coupon from './Coupon';
import MyPagination from './Pagination';
import CheckGoodsCard from './CheckGoodsCard';
import MyCheckbox from './Checkbox';
import Button from './Button';
import Form from './Form';
import MyTabs from './Tabs';
import Modal from './Modal';
import WishGoodsCard from './WishGoodsCard';
import PartyCategory from './PartyCategory';
import PartyEntryGoodsCard from './PartyEntryGoodsCard';
import BigCoupon from './BigCoupon';
import Filter from './Filter';
import Sort from './Sort';
import NumberInput from './NumberInput';
import MySelect from './Select';
import Tree from './Tree';
import StoreFilter from './StoreFilter';
import Incrementer from './Incrementer';
import Price from './Price';
import ImageUploder from './ImageUploder';
import TextArea from './TextArea';
import ConcatUs from './ConcatUs';
import MyRadio from './Radio';
import BigForm from './BigForm';
import RoundCheckbox from './RoundCheckbox';
import Nav from './Nav';
import Stars from './Stars';
import UserLayout from '@/layouts/UserLayout';
import MyInput from './Input';
import Divider from './Divider';
import OtherLoginType from './OtherLoginType';
import UserTabs from './UserTabs';
import PhoneNumber from './PhoneNumber';
import Captcha from './Captcha';
import Password from './Password';
import MyTreeSelect from './TreeSelect';
import PasswordInput from './PasswordInput';

export default Vue => {
    [Category, HomeGoodsCard, PasswordInput, MyTreeSelect, UserTabs, Password, Sort, PhoneNumber, Captcha, OtherLoginType, Stars, Divider, MyInput, UserLayout, Nav, NumberInput, MyRadio, ConcatUs, RoundCheckbox, BigForm, TextArea, Price, ImageUploder, Incrementer, StoreFilter, MySelect, WishGoodsCard, Tree, Filter, HomeGoodsList, BigCoupon, Navigator, Form, Coupon, MyPagination, PartyEntryGoodsCard, PartyCategory, CheckGoodsCard, MyCheckbox, Button, MyTabs, Modal].forEach(com => Vue.component(com.name, com));
    [Badge, Carousel, Pagination, DatePicker, Icon, Timeline, TreeSelect, Empty, Popconfirm, Checkbox, Input, FormModel, Select, Tabs, Space, Radio, Row, Col, Table, InputNumber].forEach(com => Vue.use(com));
}