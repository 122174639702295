<template>
    <InputNumber v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import { InputNumber } from 'ant-design-vue';
export default {
    name: 'NumberInput',
    components: { InputNumber },
    inheritAttrs: false,
    data() {
        return {
            price: true
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-input-number {
    border-radius: 0;
    border: 1px solid #D7D7D7;
    width: 110px;

    &.ant-input-number-lg {
        height: 36px;

        ::v-deep .ant-input-number-input-wrap {
            input {
                height: 36px;
            }
        }
    }
}
</style>