/**
 * 其他接口文件
 */

import request from './request';

export default {
    /**
     * 获取商品店铺分类(多级或者全分类树)
     */
    shopCategory: (shopId, parentCategoryId = null) => request.get('detail/shopCommodityType', { params: { shopId, parentCategoryId }, no: true }),
    /**
     * 联系商家
     */
    concatShop: model => request.post('detail/addShopLeaveMessage', model),
    /**
     * 获取商品分类
     */
    shopCategoryList: showType => request.get('user/category/allList', { headers: { showType }, no: true }),
}