/**
 * 混入文件
 */


/**
* 支付方式
*/
export const paymentOptions = {
    1: 'paypall',
    2: 'stripe',
    3: 'receivePay',
    4: 'qiCard',
    5: 'balancePay',
    6: 'mixPay'
}

export default {
    methods: {
        /**
         * 获取支付方式
         */
        getPaymentType(type) {
            return this.$t(paymentOptions[type] || '');
        },
        /**
         * 分割图片
         */
        si(str) {
            if (!str) {
                return [];
            }
            if (typeof str === 'string') {
                return str.split(',');
            }
            return str;
        },
        /**
         * 截取第一张图片
         */
        sf(str) {
            if (!str) return '';
            if (typeof str === 'string') {
                return str.split(',')[0];
            }
            if (Array.isArray(str)) {
                return str[0];
            }
            return str;
        },
        /**
         * 分割图片
         */
        splitImage(str) {
            return (str?.split(',') || [])[0];
        }
    }
}