<template>
    <ATreeSelect :value="value" @change="v => $emit('input', v)" tree-data-simple-mode
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" v-on="$listeners" v-bind="$attrs" />
</template>

<script>
import { TreeSelect as ATreeSelect } from 'ant-design-vue';

export default {
    name: "TS",
    inheritAttrs: false,
    components: { ATreeSelect },
    props: ['value']
}
</script>

<style lang="scss" scoped>
.ant-select {
    ::v-deep {
        .ant-select-selection {
            height: 36px !important;
            border-radius: 0 !important;
        }

        .ant-select-selection__rendered {
            line-height: 34px !important;
        }

        .ant-select-selection-selected-value {
            line-height: 34px;
        }
    }
}
</style>