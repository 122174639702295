<template>
    <div class="party-category-wrapper" :ar="$i18n.locale === 'ar'">
        <nav :ar="$i18n.locale === 'ar'">
            <div>
                <h1>{{ obj?.activityName }}</h1>
                <img src="@/assets/imgs/home/clock.png" alt="">
                <div style="display: flex;">
                    <span>{{ $t('partyTime') }}</span>
                    <div style="direction: ltr;">{{ dayjs(obj?.startTime).format('YYYY.MM.DD') }} - {{
                        dayjs(obj?.endTime).format('YYYY.MM.DD')
                    }}</div>
                </div>
            </div>
            <div v-show="showMore" @click="$router.push({ name: 'partyDetail', params: { id: obj?.activityId } })">
                <span v-t="'viewMore'"></span>
                <img src="@/assets/imgs/home/形状 22.png" alt="">
            </div>
        </nav>
        <div class="list">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'PartyCategory',
    data() {
        return {
            dayjs
        }
    },
    props: {
        /**
         * 是否显示更多
         */
        showMore: Boolean,
        /**
         * 标题
         */
        obj: Object,
    }
}
</script>

<style lang="scss" scoped>
.party-category-wrapper {
    &[ar] {
        direction: rtl;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 19px;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 20px 0 25px;
        height: 56px;
        background: #EEF8FE;
        border-radius: 4px 4px 0px 0px;

        &[ar] {
            direction: rtl;
            padding: 0 25px 0 20px;

            &>div {
                &:first-child img {
                    margin: 0 35px 0 9px;
                }

                &:last-child img {
                    transform: rotate(180deg);
                }
            }
        }

        h1 {
            font-weight: 500;
            font-size: 20px;
            color: #333333;
        }

        &>div {
            display: flex;
            align-items: center;

            &:first-child img {
                margin: 0 9px 0 35px;
            }

            &:last-child {
                cursor: pointer;
                width: 90px;
                height: 32px;
                background: #FFFFFF;
                border: 1px solid #D1D1D1;
                border-radius: 4px;
                font-size: 12px;
                color: #666666;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
            }
        }
    }
}
</style>