<template>
    <div class="nav-wrapper" :ar="$i18n.locale === 'ar'">
        <span v-t="'home'"></span>
        <img src="@/assets/imgs/home/arrow_right_8x11.png" alt="">
        <span v-t="$route.name"></span>
    </div>
</template>

<script>
export default {
    name: 'Nav'
}
</script>

<style lang="scss" scoped>
.nav-wrapper {
    display: flex;
    align-items: center;
    color: #666666;
    padding: 20px 0 25px;
    gap: 10px;

    &[ar] {
        direction: rtl;

        img {
            transform: rotate(180deg);
        }
    }
}
</style>