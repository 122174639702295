export default {
  newShare: "分享积分",
  title: "ZAYYA 商城",
  welcome: "您好，欢迎来到 ZAYYA 商城！",
  welcomeShort: "欢迎来到 ZAYYA 商城！",
  Happy:"生活很美好 ^_^",
  payment: "支持货到付款！",
  zh: "简体中文",
  phoneCode: "手机东智",
  download: "下载",
  en: "English",
  ar: "العربية",
  zhMoney: "CNY",
  moneyMark: "¥",
  exit: "退出登录",
  pleaselogin:"请先登录",
  goods: "商品",
  search: "搜索",
  searchPh: "请输入关键词进行搜索",
  wish: "我的分享",
  collect: "收藏夹",
  cart: "购物车",
  home: "首页",
  allBrand: "所有品牌",
  allStore: "所有门店",
  coupon: "优惠券",
  couponA: "优惠券：",
  partyEntry: "活动入口",
  sellerCenter: "卖家中心",
  concat: "联系我们",
  about: "关于平台",
  open: "我要开店",
  scoreRule: "积分规则",
  qa: "常见问题",
  map: "站点地图",
  paymentOptions: "支付方式",
  paypall: "PayPal",
  stripe: "Stripe",
  receivePay: "货到付款",
  fovar: "关注我们",
  facebook: "Facebook",
  tweeter: "X",
  youtube: "Youtube",
  instaram: "Instagram",
  private: "隐私条款",
  orderTerm: "账单条款及细则",
  copyright: "版权所有 ©2023   ZAYYA 商城",
  beian: "沪公网安备 3101000000002948号 | 沪ICP备15000000号-1",
  allCategories: "所有类别",
  viewAll: "查看全部",
  backToLastPage: "返回上一页面",
  messages: "消息列表",
  myCoupon: "我的优惠券",
  all: "全部",
  used: "已使用",
  unused: "未使用",
  expired: "已过期",
  seller: "商家",
  platform: "平台",
  discount: "折",
  goUse: "去使用",
  myCollection: "我的收藏夹",
  salesTitle: "订单详情",
  checkAll: "全选",
  removeCollection: "移除收藏夹",
  total: "共{total}页",
  myOrder: "我的订单",
  key: "关键词",
  keyPh: "商品标题关键词",
  orderType: "订单类型",
  orderTypePh: "请选择订单类型",
  orderStatus: "订单状态",
  orderStatusPh: "请选择订单状态",
  orderTime: "交易时间",
  startTime: "开始时间",
  endTime: "结束时间",
  to: "至",
  allOrder: "所有订单",
  unPay: "待付款",
  unSend: "待发货",
  unReceive: "待收货",
  unAssem: "待评价",
  maintaining: "售后中",
  search: "搜索",
  baby: "宝贝",
  price: "价格",
  count: "数量",
  paiedMoney: "实付款",
  action: "操作",
  orderTimeA: "订单时间：",
  orderNo: "订单号：",
  containPostFee: "含运费：",
  orderDetail: "订单详情",
  pay: "付款",
  cancel: "取消",
  applyReback: "申请退款",
  confirmReceive: "确认收货",
  viewTansform: "查看物流",
  applyMaintain: "申请售后",
  goAssem: "去评价",
  recall: "撤回售后",
  platformAppeal: "平台申诉",
  viewAssem: "查看评价",
  confirm: "提交",
  confirmReceived: "确认已收货",
  close: "关闭",
  ok: "确定",
  transformCompany: "物流公司",
  transformNo: "物流单号",
  confirmReceiveGoods: "您已收到商品并确认好了吗？",
  myWishList: "我的分享",
  removeWishList: "移除心愿单",
  copyLink: "复制链接",
  orderRecords: "下单记录",
  myAddress: "我的地址",
  receiver: "收件人",
  receiverPh: "请输入收件人姓名",
  concatPhone: "联系电话",
  concatPhonePh: "请输入联系电话",
  receiveAddress: "收件地址",
  receiveAddressPh: "请选择国家-省份-城市-区域",
  detailAddress: "详细地址",
  detailAddressPh: "请输入详细地址（具体的门牌号）",
  postCode: "邮编",
  postCodePh: "请输入邮政编码",
  required: "{t}不能为空",
  minMax: "{t}的长度为{min}至{max}位",
  min: "{t}的最小长度为{min}位",
  max: "{t}的最大长度为{max}位",
  addAddress: "新增收货地址",
  save: "保存",
  myAddresses: "我的收货地址",
  belongsToAddress: "所在地区",
  mobileOrPhone: "电话/手机号",
  defaultAddress: "默认地址",
  setDefaultAddress: "设为默认地址",
  delete: "删除",
  orderUser: "下单用户：",
  orderGoods: "下单商品：",
  xiadanDate: "下单时间：",
  score: "积分",
  moneyCoupon: "元优惠券",
  viewMore: "查看更多",
  partyTime: "活动时间：",
  newCoupon: "新人优惠券",
  newEnjoy: "新人专享，下单享折扣",
  immediatelyGet: "立即领取",
  sort: "排序",
  salesVolume: "销量",
  filter: "筛选",
  priceRange: "价格区间",
  choseBrand: "品牌选择",
  choseBrandPh: "请选择品牌",
  reset: "重置",
  credit: "信用",
  storeType: "店铺类型",
  storeTypePh: "请选择店铺类型",
  choseArea: "区域选择",
  choseAreaPh: "请选择区域",
  changeStyle: "切换样式",
  fans: "粉丝：",
  selfSell: "自营",
  enterStore: "进入店铺",
  money: "金额",
  storeName: "门店名称：",
  moveToCollection: "移入收藏夹",
  addToWishlish: "加入我的分享",
  payMoney: "结算",
  amount: "合计（不含运费）：",
  checked: "已选择",
  jian: "件",
  iWantleaveMessage: "我要留言",
  inputMessage: "请输入留言内容...",
  confirmMessage: "提交留言",
  searchResult: "搜索结果",
  appeal: "平台申诉",
  confirmShouhou: "提交售后",
  appealDesc: "申诉描述",
  uploadImage: "上传图片",
  appealDescPh: "请输入申诉描述",
  shouhouType: "售后类型",
  shouhouTypePh: "请选择售后类型",
  shouhouGoods: "售后商品",
  repayMoney: "退款金额",
  repayMoneyPh: "请输入退款金额",
  repayReason: "退款原因",
  repayReasonPh: "请输入退款原因",
  transformInfo: "物流信息",
  transformStatus: "物流配送状态",
  transformSendInfo: "物流配送信息",
  choseReceiveAddress: "选择收货地址",
  receiveInfo: "收货信息",
  modifyAddress: "修改本地址",
  confirmOrderInfo: "确认订单信息",
  storeNameHeader: "门店名称",
  xiaoji: "小计",
  amountContainPostfee: "合计（含运费：{s}{m}）",
  paiedMoneyColon: "实付款：",
  confirmOrder: "提交订单",
  remark: "备注：",
  postType: "运送方式：",
  post: "快递",
  chosePayment: "选择支付方式",
  gotoPay: "去付款",
  finishOrder: "您已下单，请耐心的等待收货!",
  back: "返回",
  viewOrder: "查看订单",
  cashOnDeliveryTip:
    "游客选择货到付款无法查阅订单和确认收货，不支持无理由退换货，注册会员优惠多多",
  continuePay: "继续付款",
  registerVIP: "注册会员",
  currentBrand: "当前品牌：",
  toBeSeller: "成为卖家",
  sellerName: "商户名称",
  sellerNamePh: "请输入商户名称",
  invalidPhone: "手机号格式不正确",
  emailAddress: "邮箱地址",
  emailAddressPh: "请输入邮箱地址",
  invalidEmail: "邮箱地址不正确",
  storeAddress: "门店地址",
  choseCountryPh: "请选择国家",
  choseProvincePh: "请选择省份",
  choseCityPh: "请选择城市",
  manageClass: "经营类目",
  brandAuth: "品牌授权资料",
  clickUpload: "点击上传",
  single: "（单选）",
  manageLicense: "营业执照及行业相关资质证明",
  exclusiveShop: "专卖店",
  flagshipStore: "旗舰店",
  typeShow: "类型展示",
  joinDesc: "入驻即代表同意本平台的商户",
  joinAgreeOn: "《入驻协议》",
  auditStatus: "审核状态：",
  auditing: "审核中",
  auditSuccess: "审核成功",
  failReason: "拒绝原因：",
  auditSuccessResult:
    "商户地址和账号密码 已通过邮箱/短信发送通知，请查收，有疑问请及时与平台客服联系。",
  reSubmit: "重新提交",
  qa: "常见问题",
  privateTerm: "隐私条款",
  scoreRule: "积分规则",
  billTerm: "账单条款及细则",
  id: "ID：{v}",
  editInfo: "编辑信息",
  collectGoods: "收藏商品",
  collectStore: "收藏店铺",
  username: "用户名",
  usernamePh: "请输入用户名",
  phone: "手机号",
  unbind: "未绑定",
  emailAccount: "邮箱账号",
  emailAccountPh: "请输入邮箱账号",
  password: "密码",
  passwordPh: "请输入密码",
  inputPasswordAgain: "请再次输入密码",
  confirmPassword: "确认密码",
  bindPhone: "绑定手机号",
  changeEmail: "更换邮箱",
  concatSeller: "联系商家",
  focusOnStore: "关注店铺",
  immediatelyGetBr: "立即<br />领取",
  couponGoods: "以下商品参与活动：",
  hadAccount: "已有账号？",
  gotoLogin: "去登录",
  noAccount: "还没有账号？",
  gotoRegister: "去注册",
  forgotPassword: "忘记密码？",
  register: "注册",
  accountPh: "用户名/手机号/邮箱",
  login: "登录",
  otherLoginType: "其他登录方式",
  emailRegister: "邮箱注册",
  phoneRegister: "手机号注册",
  capthcaPh: "请输入验证码",
  getCaptcha: "获取验证码",
  otherRegisterType: "其他注册方式",
  bindEmail: "绑定邮箱",
  bindPhone: "绑定手机号",
  phonePh: "请输入手机号码",
  recommendGoods: "推荐商品",
  allGoods: "所有商品",
  store: "门店",
  brand: "品牌",
  quotationMark: "“{v}”",
  passwordNotSame: "两次输入的密码不一致",
  captcha: "验证码",
  invalidEmail: "无效的邮箱格式",
  secondResend: "{s}秒后再发送",
  invalidPhone: "无效的手机号",
  askExit: "您确定要退出吗？",
  bind: "绑定",
  askDelete: "您确定要删除吗？",
  emailFind: "邮箱找回",
  phoneFind: "手机号找回",
  changePhone: "更换手机号",
  oldEmailAccount: "原邮箱账号",
  newEmailAccount: "新邮箱账号",
  oldPhone: "原手机号",
  newPhone: "新手机号",
  oldPassword: "原密码",
  newPassword: "新密码",
  joinAgreement: "入驻协议",
  hotRecommend: "热门推荐",
  zhekou: "{v}折",
  xiadanzhekou: "下单享折扣",
  cancelFocus: "取消关注",
  allA: "所有",
  unreadAgreement: "请先阅读《入驻协议》并勾选同意",
  shopPic: "店铺图片",
  rejected: "已拒绝",
  passed: "已通过",
  goodsDetail: "商品详情",
  goodsComment: "商品评论",
  originPrice: "原价：",
  priceA: "价格：",
  collectA: "收藏",
  salesed: "已售出：{v}",
  guessYouLike: "猜你喜欢",
  commentCount: "{v}条评论",
  activeEndTime: "活动结束时间：",
  sendTime: "发货时间：{v}",
  sellerA: "卖家：{v}",
  viewShop: "查看店铺",
  addToCart: "加入购物车",
  immediatelyBuy: "立即购买",
  topSalesGoods: "畅销商品",
  rateScore: "评分：",
  giveScore: "送积分：{v}",
  shou: "收",
  choseReceiveAddressA: "请选择收货地址",
  shouhoubaozhang: "售后保障",
  recommendBrand: "品牌推荐",
  tradeDone: "交易完成",
  askRepayback: "该订单确定取消并退款吗？",
  confirmReceiveA: "您已收到商品并确认好了吗？",
  changeAvatar: "更换头像",
  countLessOne: "商品数量不能少于1",
  noSpec: "请选择规格",
  msgEmpty: "留言内容不能为空",
  choseCoupon: "请选择优惠券",
  maintainRejected: "售后未通过",
  tradeCancel: "交易取消",
  askReturn: "您确定要撤回吗？",
  askCancel: "您确定要取消吗？",
  concater: "联系人：",
  concatPhoneA: "联系电话：",
  receiveAddressA: "收货地址：",
  transformCom: "物流公司：",
  transformNoA: "物流单号：",
  payTime: "付款时间：",
  paymentType: "付款方式：",
  orderInfo: "订单信息",
  sendTimeA: "发货时间：",
  maintainTime: "售后时间：",
  maintainDesc: "售后描述",
  returnType: "退款类型：",
  returnMoney: "退款金额：",
  sellerRecall: "商家回复：",
  appealDesc: "申诉描述",
  viewTransformInfo: "查看物流信息",
  onlyReturnMoney: "仅退款",
  returnMoneyAndGoods: "退货退款",
  singlePrice: "单价（元）",
  totalPrice: "商品总价：",
  postFee: "邮费：",
  returnPoint: "可返积分：{v}积分",
  maintainPassed: "售后成功",
  maintainUnsend: "售后通过待寄回",
  appealling: "申诉中",
  appealUnpass: "申诉不通过",
  appealPassed: "申诉通过",
  sendUnConfirm: "寄回待确认",
  buyGoods: "拍下宝贝",
  sellerSendGoods: "卖家发货",
  commentOrder: "评价订单",
  orderDone: "订单完成",
  editInfo: "修改信息",
  leftPayTime: "剩余支付时间：{v}",
  cancelOrder: "取消订单",
  descMatch: "描述相符：",
  commentPh: "最多可评价300个字",
  image: "图片：",
  commentContentEmpty: "评论内容不能为空",
  singlePriceA: "单价",
  leftReceiveTime:
    "您的订单还剩{v}天将自动确认，若未收到商品，请及时联系卖家！",
  maintainNoGoods: "请至少选择一项要申请售后的商品",
  repaybacking: "退款中",
  cancelAppealling: "撤回申诉",
  sendBack: "寄回",
  viewSendBackTransform: "查看寄回物流",
  appealA: "平台申诉",
  confirmA: "确认",
  askCancelAppeal: "您确定要撤销申诉吗？",
  refundDesc: "退款描述：",
  chineseClass: "中文经营类目",
  englishClass: "英文经营类目",
  arClass: "阿拉伯文经营类目",
  paySuccess: "支付成功",
  payFailed: "支付失败",
  day: "天",
  returnAddress: "退货地址：",
  unchoseGood: "请选择商品",
  repayBack: "退款",
  balance: "钱包余额：",
  viewDetail: "查看明细",
  currentBalance: "当前余额（元）",
  amountBalance: "累积余额（元）",
  paymentPassword: "支付密码",
  recharge: "充值",
  earnDetail: "收入明细",
  payDetail: "支出明细",
  platAdjust: "平台调整",
  consume: "消费",
  trandeType: "交易类型",
  adjustReason: "调整原因：",
  moneyA: "金额：",
  time: "时间：",
  unbindPhone: "未绑定手机号",
  unbindEmail: "未绑定邮箱",
  validEmail: "邮箱验证",
  validPhone: "手机号验证",
  setPaymentPasswordTip: "请输入6位数字的支付密码",
  rechargeMoney: "充值金额：",
  rechargeMoneyPh: "请输入充值金额",
  paymentOptionsA: "支付方式：",
  paginationTip: "请按回车键跳转",
  qicard: "Qi Card",
  unsetPaymentPassword: "您还未设置支付密码，请先设置",
  mixPay: "混合支付",
  balancePay: "余额支付",
  currentPoints: "当前积分",
  amountPoints: "累计积分",
  RedeemPoints: "兑换余额",
  Purchase : "主动下单",
  Share : "分享下单",
  All:'全部',
  Underreview:"审核中",
  Approved:'审核通过',
  Reviewrejected:"审核驳回",
  ApprovalStatus:"审核状态",
  ApplicationTime:"申请时间",
  redeemedNumber:"兑换积分数量",
  Reasonforrejection:"驳回原因",
  RedeemAll:"全部兑换",
  EstimatedRedemptionAmount:"预计兑换",
  RedemptionInstructions:"兑换说明",
  canredeempoints:"满***积分才可兑换",
  pointConfirm:'申请兑换',
  jifen:'积分',
  orderUser:"下单用户",
  OrderNo:'订单编号',
  orderGoods:"下单商品",
  orderTime:"下单时间",
  exchangesuccess:"兑换申请成功，等待审核",
  nothaveenoughpoints:"您没有足够的积分",
  int_tip:"请输入整数",
  email:"邮箱地址",
	Contactnumber:"联系电话",
	Message:"问题描述",
  Sendemail:'发送邮件',
	Pleaseenterthecorrectemailaddress:'请输入正确的邮箱地址',
	Pleasefillintheinformationandsubmit:'请将信息填写完整后提交',
  sendsuccess:'提交成功',
  username:'姓名',
  alipay:'支付宝',
  paysuccess:"确认支付完成？",
  SystemModification:"系统修改",
  Rechargebalance:'余额充值'
};
